import React, { useEffect, useMemo, useState } from "react";
import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import styles from "../Podcast/podcastContainer.module.scss";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CustomButton from "../../reusable/Button/Button";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { ParticularBookDetails } from "./ParticularBookDetails";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { ConfirmationModal } from "../../Modals/ConfirmationModal";
import { axiosInstance } from "../../../redux/axiosBase/axiosInstance";
import { isEmtpty } from "../../../utils/helper";
import { CustomAlert } from "../../reusable/Alerts/Alert";
import { useDispatch, useSelector } from "react-redux";
import { updateBookList } from "../../../redux/bookReducer/bookSlice";

export const ParticularBook = React.memo(
  ({ selectedBook, setselectedBook }) => {
    const [currentBook, setCurrentBook] = useState();
    const [edit, setEdit] = useState(true);
    const [openModal, setOpenModal] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(null);
    const [alertMessage, setAlertMessage] = useState(false);
    const dispatch = useDispatch();
    const bookState = useSelector((state) => state.books);
    const { bookList = [], totalSize = 0, nextOffset = 0 } = bookState || {};

    const data = useMemo(() => ({}), []);

    useEffect(() => {
      setCurrentBook(selectedBook);
    }, [selectedBook]);

    const updateSingle = (key, value) => {
      let newdata = { ...currentBook };
      newdata[key] = value;
      setCurrentBook(newdata);
    };

    const editAndSave = (val) => {
      setAlertMessage(false);
      setEdit((val) => !val);
      if (val === true) {
        return;
      } else {
        setOpenModal(true);
      }
    };

    const editBook = async () => {
      const {
        title,
        authorId,
        imageUrl,
        description,
        amazonUrl,
        flipkartUrl,
        buyOnAmazon,
        buyOnFlipkart,
      } = currentBook;
      if (
        isEmtpty(title) ||
        isEmtpty(authorId) ||
        isEmtpty(imageUrl) ||
        isEmtpty(description)
      ) {
        alert("Please Fill the mandatory fields");
        return;
      }

      let newBook = {
        ...currentBook,
        title,
        authorId,
        imageUrl,
        description,
        buyOnAmazon,
        amazonUrl,
        buyOnFlipkart,
        flipkartUrl,
      };
      const response = await axiosInstance.post("/api/book/update", newBook);
      setAlertMessage(response?.data?.success);

      if (response?.data?.success) {
        data.alertMessage = 'Book Edited Successfully';
      }

      const editedList = bookList?.map((ins) => {
        if (currentBook?.bookId === ins?.bookId) {
          ins = response?.data?.data;
        }
        return ins;
      });

      dispatch(
        updateBookList({
          bookList: editedList,
          totalSize: totalSize,
          nextOffset: nextOffset,
        })
      );
      setOpenModal(false);
    };

    const deleteBook = async () => {
      const response = await axiosInstance.post(
        `api/book/remove?bookId=${currentBook?.bookId}`
      );
      const editedList = bookList?.filter((ins) => {
        return ins?.bookId !== currentBook?.bookId;
      });

      dispatch(
        updateBookList({
          eventList: editedList,
          totalSize: totalSize,
          nextOffset: nextOffset,
        })
      );
      setAlertMessage(response?.data?.success);
      if (response?.data?.success) {
        data.alertMessage = 'Book Deleted Successfully';
      }
      setOpenDeleteModal(false);
    };

    return (
      <div>
        {alertMessage && (
          <CustomAlert
            message={
              alertMessage
                ? data.alertMessage
                : " Something Went Wrong"
            }
            success={alertMessage}
          />
        )}
        <div>
          <div className={styles.podcastEditRow}>
            <h1 className={styles.createHeaderext}>
              <span>
                <LibraryBooksIcon fontSize="large" />
              </span>
              Book
            </h1>
            <div className={styles.podcastEditBtns}>
              <CustomButton
                className={styles.editBtn}
                onClick={() => editAndSave(edit)}
                text={
                  <>
                    <EditOutlinedIcon fontSize="small" />
                    <span>{edit ? "Edit" : "Save"}</span>
                  </>
                }
                title={edit ? "Edit" : "Save"}
              />
              <CustomButton
                className={styles.podcastBtn}
                onClick={() => {
                  setAlertMessage(false);
                  setOpenDeleteModal(true);
                }}
                text={
                  <>
                    <DeleteOutlinedIcon fontSize="small" />
                    <span>Delete</span>
                  </>
                }
                title="Delete"
              />
            </div>
          </div>
        </div>
        <div className={styles.titleBox}></div>
        {edit ? (
          <>
            <ParticularBookDetails currentBook={currentBook} />
          </>
        ) : (
          <>
            <Grid container spacing={2} key="book-title-row">
              <Grid item xs={4} md={4} lg={4} key="book-name">
                <div className={styles.headersText}> Book Name</div>
                <div className={styles.contentText}>
                  <TextField
                    id="outlined-basic"
                    label="Book Name"
                    variant="outlined"
                    value={currentBook?.title}
                    onChange={(e) => updateSingle("title", e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item md={4} lg={4} xl={4} key="book-author">
                <div className={styles.headersText}> Author</div>
                <div>
                  {" "}
                  <TextField
                    id="outlined-basic"
                    label="Author"
                    variant="outlined"
                    value={currentBook?.authorId}
                    onChange={(e) => updateSingle("authorId", e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item md={3} lg={3} xl={4} key="book-key">
                <div className={styles.headersText}> Book Cover Image</div>
                <div>
                  <Button
                    variant="contained"
                    component="label"
                    className={styles.audioButton}
                    onChange={(e) => updateSingle("imageUrl", e.target.value)}
                  >
                    <CloudUploadIcon style={{ marginRight: "7px" }} /> Upload
                    Book Image
                    <input type="file" hidden />
                  </Button>
                  <input type="texts" value={currentBook.imageUrl} />
                </div>
              </Grid>
            </Grid>

            <div className={styles.titleBox}>
              <div className={styles.headersText}>Description</div>
              <TextField
                id="outlined-basic"
                label="Description"
                variant="outlined"
                className={styles.titleBox}
                multiline={true}
                rows={8}
                value={currentBook?.description}
                onChange={(e) => updateSingle("description", e.target.value)}
              />
            </div>

            <div className={styles.titleBox}>
              <Grid container spacing={2} key="sale-amazon-row">
                <Grid item xs={3} md={3} lg={3} key="amazon">
                  <div className={styles.headersText}> Sale on Amazon</div>
                  <div className={styles.contentText}>
                    <Select
                      id="live-select"
                      value={currentBook?.buyOnAmazon ? "yes" : "no"}
                      label="LiveStream"
                      onChange={(e) =>
                        updateSingle(
                          "buyOnAmazon",
                          e.target.value === "yes" ? true : false
                        )
                      }
                    >
                      <MenuItem value={"yes"} key={"yes-amazon-p"}>
                        Yes
                      </MenuItem>
                      <MenuItem value={"no"} key={"no-amazon-p"}>
                        No
                      </MenuItem>
                    </Select>
                  </div>
                </Grid>
                <Grid item md={9} lg={9} xl={9} key="amazon-address">
                  <div className={styles.headersText}>Amazon Link</div>
                  <div>
                    <TextField
                      id="e-address"
                      label={"Amazon Link"}
                      variant="outlined"
                      className={styles.titleBox}
                      value={currentBook?.amazonUrl}
                      onChange={(e) =>
                        updateSingle("amazonUrl", e.target.value)
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={styles.titleBox}>
              <Grid container spacing={2} key="sale-flipkart-row">
                <Grid item xs={3} md={3} lg={3} key="flipkart">
                  <div className={styles.headersText}> Sale on Flipkart</div>
                  <div className={styles.contentText}>
                    <Select
                      id="live-select"
                      value={currentBook?.buyOnFlipkart ? "yes" : "no"}
                      label="LiveStream"
                      onChange={(e) =>
                        updateSingle(
                          "buyOnFlipkart",
                          e.target.value === "yes" ? true : false
                        )
                      }
                    >
                      <MenuItem value={"yes"} key={"yes-flip"}>
                        Yes
                      </MenuItem>
                      <MenuItem value={"no"} key={"no-flip"}>
                        No
                      </MenuItem>
                    </Select>
                  </div>
                </Grid>
                <Grid item md={9} lg={9} xl={9} key="flip-link">
                  <div className={styles.headersText}>Flipkart Link</div>
                  <div>
                    <TextField
                      id="e-address"
                      label={"Flipkart Link"}
                      variant="outlined"
                      className={styles.titleBox}
                      value={currentBook?.flipkartUrl}
                      onChange={(e) =>
                        updateSingle("flipkartUrl", e.target.value)
                      }
                    />
                  </div>
                </Grid>
              </Grid>
            </div>
          </>
        )}
        {openModal && (
          <ConfirmationModal
            id="open-podcasts-edit"
            open={openModal}
            setOpen={setOpenModal}
            confirmationText={"edit"}
            onConfirmation={editBook}
            onCancel={() => setOpenModal(false)}
          />
        )}

        {openDeleteModal && (
          <ConfirmationModal
            id="open-podcasts-delete"
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            confirmationText={"delete"}
            onConfirmation={() => deleteBook()}
            onCancel={() => setOpenDeleteModal(false)}
          />
        )}
      </div>
    );
  }
);
