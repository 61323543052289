import { axiosInstance } from "../axiosBase/axiosInstance";

const eventUrl = "/api/meeting/recent?limit=10&offset=0";

const getEventList = async (options) => {
  const list = await axiosInstance.get(eventUrl, { params: options });
  return {
    eventList: [...list?.data?.data?.collection],
    totalSize: list?.data?.data?.totalSize,
    nextOffset: list?.data?.data?.nextOffset,
  };
};

const EventService = {
  getEventList,
};

export default EventService;
