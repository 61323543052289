import { format } from "date-fns";
// Load value from localStorage
export const loadState = (key) => {
  try {
    const serializedState = localStorage.getItem(key);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

// Save value in localStorage
export const saveState = async (key, state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(key, serializedState);
  } catch (e) {
    // Ignore
  }
};

// Remove value in localStorage
export const removeState = async (key) => {
  try {
    localStorage.removeItem(key);
  } catch (e) {
    return undefined;
  }
};

// Check Json
export const checkJSON = (string) => {
  try {
    JSON.parse(string);
  } catch (e) {
    return false;
  }
  return true;
};

// Prettify Json
export const prettifyJSON = (json) => {
  return checkJSON(json) ? JSON.stringify(JSON.parse(json), null, "\t") : json;
};

//K Formated
export const kFormatter = (num) => {
  if (Math.abs(num) > 99999)
    return Math.sign(num) * (Math.abs(num) / 100000).toFixed(1) + "L";
  return Math.abs(num) > 999
    ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(1) + "K"
    : Math.sign(num) * Math.abs(num);
};

// return startDate-endDate
export const displayDate = (dateArr) => {
  return `${format(dateArr[0].startDate, "dd/MM/yyyy")} - ${format(
    dateArr[0].endDate,
    "dd/MM/yyyy"
  )}`;
};
