import { saveState } from "../../utils/util";

//get OTP from server
const getOTP = async (email) => {
  return await sleep(2000);
};

// login user with OTP
const login = async (email, otp) => {
  await sleep(2000);
  saveState("user", { email });
  return { email };
};

// Logout user
const logout = async () => {
  localStorage.clear();
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const authService = {
  getOTP,
  login,
  logout,
};

export default authService;
