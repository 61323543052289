import React, { useEffect, useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import styles from "../Podcast/podcastContainer.module.scss";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CustomButton from "../../reusable/Button/Button";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { ParticularArticleDetails } from "./ParticularArticleDetails";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebaseConfig";
import { useDispatch, useSelector } from "react-redux";
import { ConfirmationModal } from "../../Modals/ConfirmationModal";
import { CustomAlert } from "../../reusable/Alerts/Alert";
import { isEmtpty } from "../../../utils/helper";
import { axiosInstance } from "../../../redux/axiosBase/axiosInstance";
import { updateMagList } from "../../../redux/magazineReducer/magazineSlice";

export const ParticularArticle = React.memo(
  ({ selectedArticle, setSelectedArticle }) => {
    const [currentArticle, setCurrentArticle] = useState();
    const [edit, setEdit] = useState(true);
    const [openModal, setOpenModal] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(null);
    const dispatch = useDispatch();
    const [alertMessage, setAlertMessage] = useState(false);
    const magzineState = useSelector((state) => state.magazines);
    const { magazineList = [] } = magzineState || {};

    useEffect(() => {
      setCurrentArticle(selectedArticle);
    }, [selectedArticle]);

    const updateSingle = (key, value) => {
      let newdata = { ...currentArticle };
      newdata[key] = value;
      setCurrentArticle(newdata);
    };

    const editAndSave = (val) => {
      setAlertMessage(false);
      setEdit(!val);
      if (val === true) {
        return;
      } else {
        setSelectedArticle(currentArticle);
        setOpenModal(true);
        // to-do save to backend also
      }
    };

    const editArticle = async () => {
      if (
        currentArticle?.imageUrl === "" ||
        isEmtpty(currentArticle?.title) ||
        isEmtpty(currentArticle?.content)
      ) {
        alert("Please Fill the mandatory fields");
        return;
      }

      let newArticle;

      const currentDate = new Date().toISOString().split("T")[0];

      newArticle = {
        magazineId: currentArticle?.magazineId,
        articleId: currentArticle?.articleId,
        imageUrl: currentArticle?.imageUrl,
        title: currentArticle?.title,
        description: currentArticle?.description,
        author: "someone",
        content: currentArticle?.content,
        pubDate: currentDate,
        readTimeMins: 4,
        readTimeText: "read time",
      };

      const response = await axiosInstance.post(
        "/api/magazine/article/update",
        newArticle
      );
      setAlertMessage(response?.data?.success);

      const editedList = magazineList?.map((ins) => {
        if (currentArticle?.articleId === ins?.articleId) {
          ins = response?.data?.data;
        }
        return ins;
      });

      dispatch(updateMagList(editedList));
      setOpenModal(false);
    };

    const deleteArticle = async () => {
      const response = await axiosInstance.post(
        `api/magazine/article/remove?magazineId=${currentArticle?.magazineId}&articleId=${currentArticle?.articleId}`
      );
      const editedList = magazineList?.filter((ins) => {
        return ins?.articleId !== currentArticle?.articleId;
      });
      dispatch(updateMagList(editedList));
      setAlertMessage(response?.data?.success);
      setOpenDeleteModal(false);
    };

    const updateArticleImage = (e) => {
      let file = e.target.files[0];
      if (file) {
        const storageRef = ref(storage, `articles/${file.name}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );
          },
          (err) => console.log(err),
          () => {
            // download url
            getDownloadURL(uploadTask?.snapshot?.ref)?.then((url) => {
              updateSingle("imageUrl", url);
            });
          }
        );
      }
    };

    return (
      <div>
        {alertMessage && (
          <CustomAlert
            message={alertMessage ? "Success" : " Something Went Wrong"}
            success={alertMessage}
          />
        )}
        <div>
          <div className={styles.podcastEditRow}>
            <h1 className={styles.createHeaderext}>
              <span>
                <LibraryBooksIcon fontSize="large" />
              </span>
              Article
            </h1>
            <div className={styles.podcastEditBtns}>
              <CustomButton
                className={styles.editBtn}
                onClick={() => editAndSave(edit)}
                text={
                  <>
                    <EditOutlinedIcon fontSize="small" />
                    <span>{edit ? "Edit" : "Save"}</span>
                  </>
                }
                title={edit ? "Edit" : "Save"}
              />
              <CustomButton
                className={styles.podcastBtn}
                onClick={() => {
                  setAlertMessage(false);
                  setOpenDeleteModal(true)
                }}
                text={
                  <>
                    <DeleteOutlinedIcon fontSize="small" />
                    <span>Delete</span>
                  </>
                }
                title="Delete"
              />
            </div>
          </div>
        </div>
        <div className={styles.titleBox}></div>
        {edit ? (
          <>
            <ParticularArticleDetails currentArticle={currentArticle} />
          </>
        ) : (
          <>
            <Grid container spacing={2} key="book-title-row">
              <Grid item xs={6} md={6} lg={6} key="book-name">
                <div className={styles.headersText}> Title </div>
                <div className={styles.contentText}>
                  <TextField
                    id="outlined-basic"
                    label="Title Name"
                    variant="outlined"
                    value={currentArticle?.title}
                    onChange={(e) => updateSingle("title", e.target.value)}
                  />
                </div>
              </Grid>

              <Grid item md={6} lg={6} xl={6} key="date-key">
                <div className={styles.headersText}> Article Cover Image</div>
                <div>
                  <Button
                    variant="contained"
                    component="label"
                    className={styles.audioButton}
                  >
                    <CloudUploadIcon style={{ marginRight: "7px" }} /> Article
                    Cover Image
                    <input
                      type="file"
                      hidden
                      onClick={(e) => updateArticleImage(e)}
                      accept="image/png, image/gif, image/jpeg"
                    />
                  </Button>
                </div>
              </Grid>
            </Grid>

            <Grid container spacing={2} key="content-row">
              <Grid item md={6} lg={6} xl={6} key="content-article">
                <div className={styles.titleBox}>
                  <div className={styles.headersText}>Content</div>
                  <TextField
                    id="outlined-basic"
                    label="Content"
                    variant="outlined"
                    className={styles.titleBox}
                    multiline={true}
                    rows={15}
                    value={currentArticle?.content}
                    onChange={(e) => updateSingle("content", e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item md={6} lg={6} xl={6}>
                {currentArticle?.imageUrl && (
                  <div style={{ paddingTop: "4.5em" }} key="article-img">
                    <img
                      src={currentArticle?.imageUrl}
                      height={400}
                      width={600}
                      alt="Article-img"
                    />
                  </div>
                )}
              </Grid>
            </Grid>
          </>
        )}
        {openModal && (
          <ConfirmationModal
            id="open-article-edit"
            open={openModal}
            setOpen={setOpenModal}
            confirmationText={"edit"}
            onConfirmation={editArticle}
            onCancel={() => setOpenModal(false)}
          />
        )}
        {openDeleteModal && (
          <ConfirmationModal
            id="open-article-delete"
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            confirmationText={"delete"}
            onConfirmation={deleteArticle}
            onCancel={() => setOpenDeleteModal(false)}
          />
        )}
      </div>
    );
  }
);
