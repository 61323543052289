import React, { useEffect, useState } from "react";
import moment from "moment";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import { Grid, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import styles from "../Podcast/podcastContainer.module.scss";
import CustomButton from "../../reusable/Button/Button";
import { ParticularEventDetails } from "./ParticularEventDetails";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  convertYYYYMMDDhhmmDateToReadableDate,
  isEmtpty,
} from "../../../utils/helper";
import { ConfirmationModal } from "../../Modals/ConfirmationModal";
import { axiosInstance } from "../../../redux/axiosBase/axiosInstance";
import { CustomAlert } from "../../reusable/Alerts/Alert";
import { useDispatch, useSelector } from "react-redux";
import { updateEventList } from "../../../redux/eventReducer/eventSlice";
import { DateTimePicker } from "../../DatePicker";

export const ParticularEvent = React.memo(
  ({ selectedEvent, setSelectedEvent }) => {
    const [currentEvent, setCurrentEvent] = useState();
    const [edit, setEdit] = useState(true);
    const [openModal, setOpenModal] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(null);
    const [alertMessage, setAlertMessage] = useState(false);
    const dispatch = useDispatch();
    const eventState = useSelector((state) => state.events);
    const { eventList = [], totalSize = 0, nextOffset = 0 } = eventState || {};

    useEffect(() => {
      setCurrentEvent(selectedEvent);
    }, [selectedEvent]);

    const updateSingle = (key, value) => {
      let newdata = { ...currentEvent };
      newdata[key] = value;
      setCurrentEvent(newdata);
    };

    const editAndSave = (val) => {
      setAlertMessage(false);
      setEdit(!val);
      if (val === true) {
        return;
      } else {
        setOpenModal(true);
        setSelectedEvent(currentEvent);
        // to-do save to backend also
      }
    };

    const editEvent = async () => {
      if (
        isEmtpty(currentEvent?.title) ||
        isEmtpty(currentEvent?.eventType) ||
        isEmtpty(currentEvent?.address) ||
        isEmtpty(currentEvent?.yyyymmddhhmm)
      ) {
        alert("Please Fill the mandatory fields");
        return;
      }

      let newEvent = {
        meetingId: currentEvent?.meetingId,
        title: currentEvent?.title,
        description: currentEvent?.description,
        yyyymmddhhmm: currentEvent?.yyyymmddhhmm,
        minutesD: currentEvent?.minutesD,
        eventType: currentEvent?.eventType,
        address: currentEvent?.address,
        isLiveStream: currentEvent?.isLiveStream,
        locationUrl: currentEvent?.locationUrl,
        streamUrl: currentEvent?.streamUrl,
      };
      const response = await axiosInstance.post(
        "/api/meeting/update",
        newEvent
      );

      const editedList = eventList?.map((ins) => {
        if (currentEvent?.meetingId === ins?.meetingId) {
          // ins = response?.data?.data;
          ins = newEvent;
        }
        return ins;
      });

      await dispatch(
        updateEventList({
          eventList: [...editedList],
          totalSize: totalSize,
          nextOffset: nextOffset,
        })
      );
      setOpenModal(false);
      setAlertMessage(response?.data?.success);
    };

    const deleteEvent = async () => {
      const response = await axiosInstance.post(
        `api/meeting/remove?meetingId=${currentEvent?.meetingId}`
      );
      const editedList = eventList?.filter((ins) => {
        return ins?.meetingId !== currentEvent?.meetingId;
      });
      dispatch(
        updateEventList({
          eventList: editedList,
          totalSize: totalSize,
          nextOffset: nextOffset,
        })
      );
      setAlertMessage(response?.data?.success);
      setOpenDeleteModal(false);
    };
    return (
      <div>
        {alertMessage && (
          <CustomAlert
            message={alertMessage ? "Success" : " Something Went Wrong"}
            success={alertMessage}
          />
        )}
        <div>
          <div className={styles.podcastEditRow}>
            <h1 className={styles.createHeaderext}>
              <span>
                <PodcastsIcon fontSize="large" />
              </span>{" "}
              Event
            </h1>
            <div className={styles.podcastEditBtns}>
              <CustomButton
                className={styles.editBtn}
                onClick={() => editAndSave(edit)}
                text={
                  <>
                    <EditOutlinedIcon fontSize="small" />
                    <span>{edit ? "Edit" : "Save"}</span>
                  </>
                }
                title={edit ? "Edit" : "Save"}
              />
              <CustomButton
                className={styles.podcastBtn}
                onClick={() => {
                  setAlertMessage(false);
                  setOpenDeleteModal(true);
                }}
                text={
                  <>
                    <DeleteOutlinedIcon fontSize="small" />
                    <span>Delete</span>
                  </>
                }
                title="Delete"
              />
            </div>
          </div>
        </div>
        <div className={styles.titleBox}></div>

        {edit ? (
          <>
            <ParticularEventDetails currentEvent={currentEvent} />
          </>
        ) : (
          <>
            <Grid container spacing={2} key="title-row">
              <Grid item xs={5} md={5} lg={6} key="file-name">
                <div className={styles.headersText}> Event Name</div>
                <div className={styles.contentText}>
                  <TextField
                    id="e-name"
                    label="Event Name"
                    variant="outlined"
                    className={styles.titleBox}
                    value={currentEvent?.title}
                    onChange={(e) => updateSingle("title", e.target.value)}
                  />
                </div>
              </Grid>
              <Grid item md={3} lg={3} xl={2} key="episode-number">
                <div className={styles.headersText}> Event Type</div>
                <div>
                  <Select
                    id="e-type-select"
                    label="Event Type"
                    value={
                      currentEvent?.eventType 
                    }
                    onChange={(e) => {
                      updateSingle("eventType", e.target.value)
                    }}
                  >
                    <MenuItem value={"offline"} key={"off"}>
                      Offline
                    </MenuItem>
                    <MenuItem value={"online"} key={"on"}>
                      Online
                    </MenuItem>
                  </Select>
                </div>
              </Grid>
              <Grid item md={3} lg={3} xl={4} key="date-key-eventP">
                <div className={styles.headersText}> Date</div>
                <div>
                  <DateTimePicker
                    label="Date"
                    value={convertYYYYMMDDhhmmDateToReadableDate(`${currentEvent?.yyyymmddhhmm}`)}
                    setValue={(date) => {
                      updateSingle(
                        'yyyymmddhhmm',
                        moment(date).format('YYYYMMDDHHmm')
                      )
                    }}
                  />
                </div>
              </Grid>
            </Grid>

            <div className={styles.titleBox}>
              <Grid container spacing={2} key="time-row">
                <Grid item xs={3} md={3} lg={3} key="time">
                  <Tooltip title="Duration of event in mintes" placeholder="top-start">
                    <div className={styles.headersText}> Duration</div>
                  </Tooltip>
                  <div className={styles.contentText}>
                    <TextField
                      id="time-event"
                      label="Duration"
                      variant="outlined"
                      placeholder="Duration in minutes"
                      className={styles.titleBox}
                      value={currentEvent?.minutesD}
                      onChange={(e) =>
                        updateSingle(
                          "minutesD",
                          e.target.value
                        )
                      }
                    />
                  </div>
                </Grid>
                <Grid item md={9} lg={9} xl={9} key="event-address">
                  <div className={styles.headersText}>
                    {currentEvent?.eventType === "offline"
                      ? "Event Address"
                      : "Event Platform"}
                  </div>
                  <div>
                    <TextField
                      id="e-address"
                      label={
                        currentEvent?.eventType === "offline"
                          ? "Event Address"
                          : "Event Platform"
                      }
                      variant="outlined"
                      className={styles.titleBox}
                      value={currentEvent?.address}
                      onChange={(e) => {
                        updateSingle("address", e.target.value)
                      }}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            <div className={styles.titleBox}>
              <Grid container spacing={2} key="map-row">
                <Grid item xs={6} md={6} lg={6} key="map-co-ordinates">
                  <div className={styles.headersText}>
                    {currentEvent?.eventType === "offline"
                      ? "Location Url"
                      : "Event Link"}
                  </div>
                  <div className={styles.contentText}>
                    <TextField
                      id="map-cordinates"
                      label={
                        currentEvent?.eventType === "offline"
                          ? "Location Url"
                          : "Event Link"
                      }
                      variant="outlined"
                      className={styles.titleBox}
                      value={currentEvent?.locationUrl}
                      onChange={(e) =>
                        updateSingle("locationUrl", e.target.value)
                      }
                    />
                  </div>
                </Grid>
                <Grid item xs={6} md={6} lg={6} key="event-desc">
                  <div className={styles.headersText}>Description</div>
                  <div className={styles.contentText}>
                    <TextField
                      id="event-desc"
                      label={"Description"}
                      variant="outlined"
                      className={styles.titleBox}
                      value={currentEvent?.description}
                      multiline={true}
                      rows={3}
                      onChange={(e) => updateSingle("description", e.target.value)}
                    />
                  </div>
                </Grid>
              </Grid>
            </div>

            {currentEvent?.eventType === "offline" && (
              <div className={styles.titleBox}>
                <Grid container spacing={2} key="link-row">
                  <Grid item xs={3} md={3} lg={3} key="link-yes-no">
                    <div className={styles.headersText}> Live Stream</div>
                    <div className={styles.contentText}>
                      <Select
                        id="live-select"
                        value={currentEvent?.isLiveStream}
                        label="LiveStream"
                        onChange={(e) =>
                          updateSingle("isLiveStream", e.target.value)
                        }
                      >
                        <MenuItem value={true} key={"yes-event"}>
                          Yes
                        </MenuItem>
                        <MenuItem value={false} key={"no-event"}>
                          No
                        </MenuItem>
                      </Select>
                    </div>
                  </Grid>
                  <Grid item md={7} lg={7} xl={7} key="event-address">
                    <div className={styles.headersText}> Live Stream Link</div>
                    <div>
                      <TextField
                        id="live-link"
                        label="Live Stream Link"
                        variant="outlined"
                        className={styles.titleBox}
                        value={currentEvent?.streamUrl}
                        onChange={(e) =>
                          updateSingle("streamUrl", e.target.value)
                        }
                      />
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
          </>
        )}

        {openModal && (
          <ConfirmationModal
            id="open-event-edit"
            open={openModal}
            setOpen={setOpenModal}
            confirmationText={"edit"}
            onConfirmation={() => editEvent()}
            onCancel={() => setOpenModal(false)}
          />
        )}

        {openDeleteModal && (
          <ConfirmationModal
            id="open-event-delete"
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            confirmationText={"delete"}
            onConfirmation={() => deleteEvent()}
            onCancel={() => setOpenDeleteModal(false)}
          />
        )}
      </div>
    );
  }
);
