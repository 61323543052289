import React, { useCallback, useState, useRef, useEffect } from "react";
import { CellMeasurer, CellMeasurerCache } from "react-virtualized";
import { VirtualizedList } from "../../reusable/VitualisedList/VirtualisedList";
import { Alert, Button, MenuItem, Snackbar, TextField } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { RenderRowCardArticle } from "./RenderRowCardArticle";
import CustomButton from "../../reusable/Button/Button";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import styles from "../Podcast/podcastContainer.module.scss";
import { axiosInstance } from "../../../redux/axiosBase/axiosInstance";
import { groupBy } from "../../../utils/helper";
import {
  updateMagId,
  updateMagList,
} from "../../../redux/magazineReducer/magazineSlice";
import { useDispatch, useSelector } from "react-redux";
import AddMagazine from "./AddMagazine";
import { UpdateMagazine } from "./UpdateMagazine";

export const MagazineSidebar = React.memo(({ onClick, articleId }) => {
  const calculatedHeight = useRef();
  const [searchByName, searchByNameHandler] = useState("");
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedEdition, setSelectedEdition] = useState("");
  const [scrollToRow, scrollToRowHandler] = useState(-1);
  const [height, setHeight] = useState(window.innerHeight - 430);
  const [allData, setAllData] = useState([]);
  const [scrollDisabled, setScrollDisabled] = useState(true);
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 96,
  });
  const [open, setOpen] = useState(false);
  const [openUpdate, setOpenUpdate] = useState(false);
  const [yearOptions, setyearOptions] = useState([]);
  const [editionOptions, setEditionOptions] = useState([]);
  const [magGroup, setMagGroup] = useState();
  const dispatch = useDispatch();
  const magzineState = useSelector((state) => state.magazines);
  const { magazineList = [] } = magzineState || {};

  const [snackbarDetails, setSnackBarDetails] = useState({
    open: false,
    type: '',
    message: '',
  })

  useEffect(() => {
    getYearAndEditionDetails();
  }, []);

  const getYearAndEditionDetails = async () => {
    const response = await axiosInstance.get("/api/magazine/list");
    let arr = response.data.data;
    const years = groupBy(arr, "yearPublished");
    setMagGroup(years);
    setyearOptions(Object.keys(years));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const navigate = useNavigate();

  useEffect(() => {
    const resizeListener = () => {
      setHeight(window.innerHeight - 430);
    };
    window.addEventListener("resize", resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    setAllData(magazineList);
  }, [magazineList]);

  useEffect(() => {
    setScrollToRow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData]);

  const filteredByNameData = (name) => {
    const searchedData = magazineList?.filter((ins) =>
      ins?.title?.toLowerCase()?.includes(name?.toLowerCase())
    );
    setAllData(searchedData);
  };

  const setScrollToRow = () => {
    const currentIndex = allData.findIndex(
      (data) => data?.articleId === articleId
    );
    if (currentIndex !== scrollToRow && currentIndex !== -1) {
      scrollToRowHandler(currentIndex);
    }
  };

  const selectCell = (index, data) => {
    scrollToRowHandler(index);
    onClick(data);
  };

  const renderRow = ({ index, style, scrollToRow, parent }) => {
    const isActive = index === scrollToRow;
    if (!(JSON.stringify(allData[0]) === "{}"))
      return (
        <div className={""} key={`podcast-list-${index}`}>
          <CellMeasurer
            key={`cell-measurer-report-card-${index}`}
            cache={cache}
            columnIndex={0}
            parent={parent}
            rowIndex={index}
          >
            {() => (
              <RenderRowCardArticle
                keyForCard={`inner-podcast-row-card-${index}`}
                podcastList={allData}
                index={index}
                style={style}
                activepodcast={isActive ? `${styles.activeRow}` : ""}
                selectCell={(index, data) => {
                  selectCell(index, data);
                }}
              />
            )}
          </CellMeasurer>
        </div>
      );
  };

  const handleKeyDown = ({ keyCode }) => {
    const data = allData[scrollToRow];
    if (keyCode === 13 && data) {
      onClick(data);
    }
  };

  const createNewMagazine = () => {
    navigate("/magazine/create-article");
  };

  const handleUpdateMagazine = () => {
    setOpenUpdate(!openUpdate);
  };

  const changeYear = (e) => {
    setSelectedYear(e.target.value);
    let editions = Object.values(magGroup)
      .flat()
      .map((i) => {
        if (i.yearPublished == e.target.value) {
          return i;
        }
      })
      .filter((i) => i);

    const result = editions.reduce((unique, o) => {
      if (!unique.some((obj) => obj.edition === o.edition)) {
        unique.push(o);
      }
      return unique;
    }, []);

    setEditionOptions(result);
  };

  const closeSnackBar = useCallback(() => {
    setSnackBarDetails((prev) => ({
      ...prev,
      open: false,
    }))
  }, []);

  const changeEdition = async (e) => {
    setSelectedEdition(e.target.value);
    const response = await axiosInstance.get(
      `api/magazine/articles?magazineId=${e?.target?.value?.id}`
    );
    dispatch(updateMagId(e?.target?.value?.id));
    dispatch(updateMagList(response?.data?.data));
  };

  const deleteMag = async () => {
    try {
      const response = await axiosInstance?.post(
        `api/magazine/remove?id=${selectedEdition?.id}`
      );
      if (!response.data.success) {
        throw new Error('Something went wrong');
      }
      setSnackBarDetails({
        open: true,
        message: 'Successfully deleted magazine',
        type: 'success',
      })
    } catch (err) {
      setSnackBarDetails({
        open: true,
        message: 'Failed to delete magazine',
        type: 'error',
      })
    }
  };

  return (
    <div>
      <Snackbar open={snackbarDetails.open} autoHideDuration={5000} onClose={closeSnackBar}>
        <Alert onClose={closeSnackBar} severity={snackbarDetails.type} sx={{ width: '100%' }}>
          {snackbarDetails.message}
        </Alert>
      </Snackbar>
      <div className={styles.yearBox}>  
        <CustomButton
          className={styles.addbtn}
          onClick={handleClickOpen}
          text={
            <>
              <div className={styles.addYearBtn}>Add Magazine</div>
            </>
          }
          title="Add Magazine"
        />

        <Button
          variant="contained"
          component="label"
          className={styles.updateBtn}
          onClick={handleUpdateMagazine}
        >
          Update Magazine
        </Button>

        <CustomButton
          className={styles.podcastBtn}
          onClick={() => {
            deleteMag();
          }}
          text={
            <>
              <DeleteOutlinedIcon fontSize="medium" />
            </>
          }
          title="Delete"
        />

      </div>
      <div className={styles.yearBox}>
        <TextField
          id="year-select"
          value={selectedYear}
          label={"Year"}
          onChange={(e) => changeYear(e)}
          className={`${styles.editionBtn} ${styles.addbtn}`}
          select
        >
          {yearOptions?.map((i) => {
            return (
              <MenuItem value={i} key={i}>
                {i}
              </MenuItem>
            );
          })}
        </TextField> 

        <TextField
          id="edition-select"
          value={selectedEdition}
          label={"Edition"}
          onChange={(e) => changeEdition(e)}
          select
          className={styles.editionBtn}
        >
          {editionOptions.map((i) => {
            return (
              <MenuItem value={i} key={i?.edition}>
                {i?.edition}
              </MenuItem>
            );
          })}
        </TextField>
      </div>
      <div className={styles.createNewBox} onClick={() => createNewMagazine()}>
        <AddCircleOutlineIcon fontSize={"large"} color="inherit" />
        <div className={styles.createText}>Add New Article</div>
      </div>
      <div className={styles.upDownPadding}>
        <TextField
          id="search-by-article-name"
          label="Search Article"
          variant="outlined"
          value={searchByName}
          onChange={(e) => {
            searchByNameHandler(e.target.value);
            filteredByNameData(e.target.value);
          }}
          className={styles.widthBox}
        />
      </div>
      <div
        className={styles.heightfilter}
        ref={calculatedHeight}
        onKeyDown={handleKeyDown}
        onMouseOver={() => setScrollDisabled(false)}
        role="button"
      >
        {allData.length === 0 && (
          <div className={styles.noData}>
            <span>{"No Articles Available"}</span>
          </div>
        )}
        {allData.length > 0 && (
          <>
            <VirtualizedList
              cache={cache}
              cardWidth={400}
              height={height}
              rowCount={allData.length}
              rowRenderer={(params) =>
                renderRow({ scrollToRow: scrollToRow, ...params })
              }
              scrollDisabled={scrollDisabled}
              scrollToRowParent={scrollToRow}
            />
          </>
        )}
      </div>
      <>
        <AddMagazine open={open} handleClose={handleClose} />
        <UpdateMagazine
          open={openUpdate}
          handleClose={() => setOpenUpdate(false)}
          mag={selectedEdition}
        />
      </>
    </div>
  );
});
