import React from "react";
import CustomButton from "../reusable/Button/Button";
import ErrorPic from "../../static/images/Wrong.svg";
import styles from "./errorStyle.module.scss";

export const ErrorPage = () => {
  const goBack = () => {
    window.location.reload();
  };

  return (
    <div className={styles.container}>
      <div className={styles.center}>
        <img
          alt="You are Not Authorised to View this Page"
          src={ErrorPic}
          className={styles.logoImage}
        />
        <h2 className={styles.marginBottom}>Oops! Something went wrong</h2>
        <p className={styles.marginBottom}>
          Please refresh the page and try again
        </p>
        <CustomButton
          className={styles.btn}
          onClick={goBack}
          text="Refresh"
          title="Refresh"
        />
      </div>
    </div>
  );
};
