import React, { useEffect, useState } from "react";
import { ArrowKeyStepper, List } from "react-virtualized";
import "react-virtualized/styles.css";

export const VirtualizedList = React.memo(
  ({
    rowCount = 0,
    height,
    cardWidth,
    scrollDisabled = true,
    columnCount = 1,
    scrollToRowParent = 0,
    isDisabled = false,
    isControlled = false,
    rowRenderer,
    mode = "cells",
    className = "remove-outline",
    cache,
    key = "",
  }) => {
    const [scrollToRow, scrollToRowHandler] = useState(-1);
    const [scrollTop, setScrollTop] = useState(0);

    useEffect(() => {
      scrollToRowHandler(scrollToRowParent);
    }, [scrollToRowParent]);

    const onScrollToChange = (params) => {
      scrollToRowHandler(params.scrollToRow);
    };

    const onScroll = (params) => {
      setScrollTop(params?.scrollTop || 0);
    };

    return (
      <ArrowKeyStepper
        columnCount={columnCount}
        rowCount={rowCount}
        mode={mode}
        scrollToRow={scrollToRow}
        onScrollToChange={onScrollToChange}
        disabled={isDisabled}
        key={key}
      >
        {({ onSectionRendered, scrollToRow }) => (
          <List
            key={key}
            onSectionRendered={onSectionRendered}
            scrollToRow={scrollToRow}
            width={cardWidth}
            height={height}
            isControlled={isControlled}
            deferredMeasurementCache={cache}
            rowHeight={cache.rowHeight}
            rowRenderer={rowRenderer}
            rowCount={rowCount}
            scrollToIndex={scrollToRow}
            scrollToAlignment="start"
            onScroll={onScroll}
            className={className}
            {...(!scrollDisabled && {
              scrollTop: scrollTop,
            })}
          />
        )}
      </ArrowKeyStepper>
    );
  }
);
