import React from "react";
import styles from "../Podcast/podcastContainer.module.scss";

export const RenderRowCardArticle = React.memo(
  ({ podcastList, index, activepodcast, selectCell, keyForCard, style }) => {
    return (
      <div key={`row-card-${keyForCard}`} className={``} style={style}>
        <div
          className={`${styles.podcastCard} ${
            activepodcast ? styles.activepodcast : ""
          }`}
          role="button"
          onClick={() => {
            selectCell(index, podcastList[index]);
          }}
        >
          <div className={styles.bookImage}>
            <img
              height={60}
              width={60}
              src={podcastList[index]?.imageUrl}
              alt="articleImage"
            />
          </div>

          <div className={styles.podcastDetails}>
            <div className={styles.bookTitle}>{podcastList[index]?.title}</div>

            <div
              className={styles.bookDesc}
              title={podcastList[index]?.description}
            >
              {podcastList[index]?.description}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
