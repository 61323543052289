import React from "react";
import moment from "moment";
import { Grid } from "@mui/material";
import styles from "../Podcast/podcastContainer.module.scss";
import {
  convertYYYYMMDDhhmmDateToReadableDate,
} from "../../../utils/helper";

export const ParticularEventDetails = React.memo(({ currentEvent }) => {
  return (
    <>
      <Grid container spacing={2} key="title-row">
        <Grid item xs={5} md={5} lg={6} key="file-name">
          <div className={styles.headersText}> Event Name</div>
          <div className={styles.contentText}>{currentEvent?.title}</div>
        </Grid>
        <Grid item md={3} lg={3} xl={2} key="episode-number">
          <div className={styles.headersText}> Event Type</div>
          <div>{currentEvent?.eventType}</div>
        </Grid>
        <Grid item md={3} lg={3} xl={4} key="date-key-">
          <div className={styles.headersText}> Date</div>
          <div>
            {moment(convertYYYYMMDDhhmmDateToReadableDate(`${currentEvent?.yyyymmddhhmm}`)).format('DD MMM, YYYY hh:mm a')}
          </div>
        </Grid>
      </Grid>

      <div className={styles.titleBox}>
        <Grid container spacing={2} key="time-row">
          <Grid item xs={3} md={3} lg={3} key="time">
            <div className={styles.headersText}> Duration</div>
            <div className={styles.contentText}>
              {currentEvent?.minutesD}
            </div>
          </Grid>
          <Grid item md={9} lg={9} xl={9} key="event-address">
            <div className={styles.headersText}>
              {currentEvent?.eventType === "offline"
                ? "Event Address"
                : "Event Platform"}
            </div>
            <div>{currentEvent?.address}</div>
          </Grid>
        </Grid>
      </div>

      <div className={styles.titleBox}>
        <Grid container spacing={2} key="map-row">
          <Grid item xs={6} md={6} lg={6} key="map-co-ordinates">
            <div className={styles.headersText}>
              {currentEvent?.eventType === "offline"
                ? "Maps Co Ordinates"
                : "Event Link"}
            </div>
            <div className={styles.contentText}>
              <a href={currentEvent?.locationUrl}>{currentEvent?.locationUrl}</a>
            </div>
          </Grid>
          <Grid item xs={6} md={6} lg={6} key="description">
            <div className={styles.headersText}>
              Description
            </div>
            <div>
              {currentEvent?.description}
            </div>
          </Grid>
        </Grid>
      </div>

      {currentEvent?.eventType === "offline" && (
        <div className={styles.titleBox}>
          <Grid container spacing={2} key="link-row">
            <Grid item xs={3} md={3} lg={3} key="link-yes-no">
              <div className={styles.headersText}> Live Stream</div>
              <div className={styles.contentText}>{currentEvent?.isLiveStream ? "Yes": "No"}</div>
            </Grid>
            <Grid item md={7} lg={7} xl={7} key="event-address">
              <div className={styles.headersText}> Live Stream Link</div>
              <div>
                <a href={currentEvent?.streamUrl}>{currentEvent?.streamUrl}</a>
              </div>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );
});
