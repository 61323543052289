import { axiosInstance } from "../axiosBase/axiosInstance";

const dashBoardSummary = "/api/action/summary";

const getDashBoardData = async (options) => {
  const dashBoardSummaryResponse = await axiosInstance.get(dashBoardSummary, {
    params: options,
  });

  return {
    dashBoardSummary: dashBoardSummaryResponse?.data?.data,
  };
};

const dashBoardService = {
  getDashBoardData,
};

export default dashBoardService;
