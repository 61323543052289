import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { loadState } from "../../utils/util";
import authService from "./authService";

// get OTP from server
export const requestOTP = createAsyncThunk(
  "auth/requestOTP",
  async (email, thunkAPI) => {
    try {
      await authService.getOTP(email);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// login user with OTP
export const login = createAsyncThunk(
  "auth/login",
  async (email, otp, thunkAPI) => {
    try {
      return await authService.login(email, otp);
    } catch (error) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

// logout user
export const logout = createAsyncThunk("auth/logout", async () => {
  authService.logout();
});

// Send OTP to server to login user

// Get user from localStorage
const user = loadState("user");

const initialState = {
  user: user ? user : null,
  isLoading: false,
  isLoginError: false,
  isOTPSent: false,
  isOTPError: false,
  isSuccess: false,
  message: "",
};

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoginError = false;
      state.isLoading = false;
      state.isOTPSent = false;
      state.isOTPError = false;
      state.isSuccess = false;
      state.message = "";
    },
    updateUser: (state, action) => {
      state.user = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(requestOTP.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(requestOTP.fulfilled, (state) => {
        state.isLoading = false;
        state.isOTPSent = true;
      })
      .addCase(requestOTP.rejected, (state, action) => {
        state.isLoading = false;
        state.isOTPError = true;
        state.message = action.payload;
      })
      .addCase(login.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload;
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false;
        state.isLoginError = true;
        state.message = action.payload;
        state.user = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.user = null;
        authSlice.caseReducers.reset(state);
      });
  },
});

export const { reset, updateUser } = authSlice.actions;

export default authSlice.reducer;
