/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { CreateNewEvent } from "./CreateNewEvent";
import { ParticularEvent } from "./ParticularEvent";
import styles from "../Podcast/podcastContainer.module.scss";
import { EventSideBar } from "./EventSideBar";
import { getEventList } from "../../../redux/eventReducer/eventSlice";

export const EventContainer = React.memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const eventState = useSelector((state) => state.events);
  const { eventList = [] } = eventState || {};
  const [newEventScreen, setNewEventScreen] = useState(true);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const eventId = id;
  const dispatch = useDispatch();
  const [sidebarData, setSideBarData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState();

  useEffect(() => {
    if (window.location.href.includes("create-event")) {
      setNewEventScreen(true);
    } else {
      setNewEventScreen(false);
    }
    dataToPassToSidebar();
  }, [location, sidebarData?.length]);

  const onClickEvent = (data) => {
    navigate(`/event/${data?.meetingId?.toString()}`);
  };

  const dataToPassToSidebar = () => {
    if (!eventList.length) {
      initialMapSetup();
    } else {
      setInnerView(eventList);
    }
  };

  const setInnerView = (data) => {
    const allData = [...data];
    const filteredData = allData?.filter((item) => item?.meetingId.toString() === eventId?.toString());
    setSelectedEvent(filteredData?.length > 0 ? filteredData[0] : []);
    setSideBarData(eventList);
  };

  const initialMapSetup = async () => {
    try {
      setLoading(true);
      const response = await dispatch(getEventList());
      setSideBarData(response.payload.eventList);
      setInnerView(response.payload.eventList);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.podcastContainer}>
      {loading ? (
        <>loading</>
      ) : (
        <>
          <div className={styles.podcastSideBar}>
            <EventSideBar
              onClick={onClickEvent}
              podcastData={sidebarData}
              eventId={eventId}
            />
          </div>
          <div className={styles.podcastContent}>
            {newEventScreen ? (
              <CreateNewEvent />
            ) : (
              <ParticularEvent
                key={selectedEvent.meetingId}
                selectedEvent={selectedEvent}
                setSelectedEvent={setSelectedEvent}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
});

export default EventContainer;
