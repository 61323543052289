import React, { useEffect, useMemo, useState } from "react";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import { Grid, Switch, TextField } from "@mui/material";
import styles from "./podcastContainer.module.scss";
import DatePicker from "react-date-picker";
import CustomButton from "../../reusable/Button/Button";
import AudioPlay from "./AudioPlay";
import { axiosInstance } from "../../../redux/axiosBase/axiosInstance";
import { isEmtpty, formatDateToyyyymmddFormat } from "../../../utils/helper";
import { useDispatch, useSelector } from "react-redux";
import { updatePodcastList } from "../../../redux/podcastReducer/podcastSlice";
import { CustomAlert } from "../../reusable/Alerts/Alert";

export const CreateNewPodcast = () => {
  const initialState = useMemo(() => ({
    episodeId: '',
    yyyymmdd: '',
    title: '',
    description: '',
  }), []);

  const [podState, setPodState] = useState(initialState);
  const [releaseToday, setReleaseToday] = useState(true);
  const [fileUrl, setfileUrl] = useState("");
  const dispatch = useDispatch();
  const podcastState = useSelector((state) => state.podcasts);
  const { podcastList = [], totalSize = 0, nextOffset = 0 } = podcastState;
  const [alertMessage, setAlertMessage] = useState(false);

  const updateSingle = (key, value) => {
    let newdata = { ...podState };
    newdata[key] = value;
    setPodState(newdata);
  };

  const handleCancel = () => {
    //clears all State
    setPodState(initialState);
    setReleaseToday(true);
  };

  const toggleReleaseToday = () => {
    setReleaseToday((prev) => !prev);
  };

  const handleCreateNew = async () => {
    if (
      fileUrl === "" ||
      isEmtpty(podState?.title) ||
      isEmtpty(podState?.episodeId) ||
      isEmtpty(podState?.description)
    ) {
      alert("Please Fill the mandatory fields");
      return;
    }

    let newPodcast = {
      title: podState?.title,
      description: podState?.description,
      content: podState?.description,
      yyyymmdd:
        JSON.stringify(new Date()).slice(1, 11).replaceAll("-", "") ||
        formatDateToyyyymmddFormat(podState?.yyyymmdd),
      episodeId: podState?.episodeId,
      episodeText: podState?.title,
      timeText: "some Episode",
      audioUrl: fileUrl,
    };

    const response = await axiosInstance.post("/api/podcast/add", newPodcast);
    setAlertMessage(response?.data?.success);
    dispatch(
      updatePodcastList({
        podcastlist: [response?.data?.data, ...podcastList],
        totalSize: totalSize,
        nextOffset: nextOffset,
      })
    );
  };

  useEffect(() => {
    if (releaseToday) {
      updateSingle("yyyymmdd", new Date());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [releaseToday]);

  // useEffect(() => {
  //   if (podState.yyyymmdd) {
  //     if(new Date().toDateString() !== podState.yyyymmdd.toDateString()) {
  //       setReleaseToday(false);
  //     } else {
  //       setReleaseToday(true);
  //     }
  //   }
  // }, [podState.yyyymmdd, releaseToday]);

  return (
    <div>
      {alertMessage && (
        <CustomAlert
          message={
            alertMessage
              ? "Podcast Added Successfully"
              : " Something Went Wrong"
          }
          success={alertMessage}
        />
      )}
      <div>
        <h1 className={styles.createHeaderext}>
          <span>
            <PodcastsIcon fontSize="large" />
          </span>
          Create New Podcast
        </h1>
      </div>
      <Grid container spacing={2} key="title-row">
        <Grid item xs={4} md={4} lg={4} key="file-name">
          <div className={styles.headersText}> File Name</div>
          <div className={styles.contentText}>
            <AudioPlay fileUrl={fileUrl} setfileUrl={setfileUrl} />
          </div>
        </Grid>
        <Grid item md={2} lg={2} xl={2} key="episode-number">
          <div className={styles.headersText}> Episode Number</div>
          <div>
            {" "}
            <TextField
              id="outlined-basic"
              label="Episode Number"
              variant="outlined"
              required
              value={podState?.episodeId}
              onChange={(e) => updateSingle("episodeId", e.target.value)}
            />
          </div>
        </Grid>
        <Grid item md={4} lg={4} xl={4} key="date-key">
          <div className={styles.headersText}> Release Date </div>
          <div>
            <DatePicker
              selected={podState?.yyyymmdd}
              onChange={(date) => updateSingle("yyyymmdd", date)}
              value={podState?.yyyymmdd}
              disabled={releaseToday}
            />
          </div>
        </Grid>
        <Grid item md={2} lg={2} xl={2} key="release-today">
          <div className={styles.headersText}> Release today </div>
          <div>
            <Switch
              checked={releaseToday}
              onChange={toggleReleaseToday}
              inputProps={{ 'aria-label': 'controlled' }}
            />
          </div>
        </Grid>
      </Grid>
      <div className={styles.titleBox}>
        <div className={styles.headersText}>Title</div>
        <TextField
          id="outlined-basic"
          label="Title"
          variant="outlined"
          className={styles.titleBox}
          value={podState?.title}
          required
          onChange={(e) => updateSingle("title", e.target.value)}
        />
      </div>

      <div className={styles.titleBox}>
        <div className={styles.headersText}>Transcription</div>
        <TextField
          id="outlined-basic"
          label="Transcription"
          variant="outlined"
          className={styles.titleBox}
          multiline={true}
          required
          rows={13}
          value={podState?.description}
          onChange={(e) => updateSingle("description", e.target.value)}
        />
      </div>
      <div className={styles.buttonRow}>
        <CustomButton
          className={styles.btn}
          onClick={() => {
            handleCancel();
          }}
          text="Cancel"
          title="Cancel"
        />
        <CustomButton
          className={styles.podcastBtn}
          onClick={() => {
            handleCreateNew();
          }}
          text="Create Podcast"
          title="Create Podcast"
        />
      </div>
    </div>
  );
};
