import { axiosInstance } from "../axiosBase/axiosInstance";

const magazineUrl = "/api/magazine/recent?limit=10&offset=0";

const getMagazineList = async (options) => {
  // const list = await axiosInstance.get(magazineUrl, { params: options });
  return {
    // magazineList: [...list.data.data.collection], // Its giving a list of recent magazines so not required
    magazineList: [{}],
  };
};

const magazineService = {
  getMagazineList,
};

export default magazineService;
