import React, { useCallback, useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
  Snackbar,
  Alert,
} from "@mui/material";
import styles from "../Podcast/podcastContainer.module.scss";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { axiosInstance } from "../../../redux/axiosBase/axiosInstance";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebaseConfig";

export const UpdateMagazine = ({ open, handleClose, mag }) => {
  const [magazine, setMagazine] = useState({});
  const [snackbarDetails, setSnackBarDetails] = useState({
    open: false,
    type: '',
    message: '',
  })

  const closeSnackBar = useCallback(() => {
    setSnackBarDetails((prev) => ({
      ...prev,
      open: false,
    }))
  }, []);

  useEffect(() => {
    setMagazine(mag);
  }, [mag]);

  const updateSingle = (key, value) => {
    let newdata = { ...magazine };
    newdata[key] = value;
    setMagazine(newdata);
  };

  const publishMagazine = async () => {
    if (
      magazine?.title === "" ||
      magazine?.coverImage === "" ||
      magazine?.issueId === "" ||
      magazine?.yearPublished === "" ||
      magazine?.edition === ""
    ) {
      alert("Please Fill All the Values");
    } else {
      try {
        const res = await axiosInstance.post("/api/magazine/update", magazine);
        if (!res.data.success) {
          throw new Error('Something went wrong');
        }
        setSnackBarDetails({
          open: true,
          message: 'Successfullt updated magazine',
          type: 'success',
        })
      } catch(err) {
        setSnackBarDetails({
          open: true,
          message: 'Failed to update magazine',
          type: 'error',
        })
      } finally {
        handleClose();
      }
    }
  };

  const uploadMagazineImage = (e) => {
    let file = e.target.files[0];
    if (!file) {
      alert("Please choose a file first!");
    }
    if (file) {
      const storageRef = ref(storage, `/magazines/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask?.snapshot?.ref)?.then((url) => {
            updateSingle("coverImage", url);
          });
        }
      );
    }
  };

  return (
    <div>
      <Snackbar open={snackbarDetails.open} autoHideDuration={5000} onClose={closeSnackBar}>
        <Alert onClose={closeSnackBar} severity={snackbarDetails.type} sx={{ width: '100%' }}>
          {snackbarDetails.message}
        </Alert>
      </Snackbar>
      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        PaperProps={{
          sx: {
            width: "50%",
          },
        }}
        open={open}
        onClose={handleClose}
      >
        <DialogTitle className={styles.modalTitle}>Update magazine</DialogTitle>
        <DialogContent style={{ height: "50vh" }}>
          {" "}
          <DialogContentText></DialogContentText>
          <Box
            noValidate
            component="form"
            sx={{
              display: "flex",
              flexDirection: "column",

              width: "auto",
              height: "100%",
            }}
          >
            <FormControl sx={{ mt: 2, minWidth: 120 }}>
              <Grid container spacing={2} key="magazine-first-row">
                <Grid item xs={6} md={6} lg={6} key="book-name">
                  <div className={styles.headersText}> Title </div>
                  <div className={styles.contentText}>
                    <TextField
                      id="outlined-basic"
                      label="Title Name"
                      variant="outlined"
                      value={magazine?.title}
                      onChange={(e) => updateSingle("title", e.target.value)}
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}></div>
                  <div className={styles.headersText}> IssueId </div>
                  <div className={styles.contentText}>
                    <TextField
                      id="outlined-basic"
                      label="IssueId"
                      variant="outlined"
                      value={magazine?.issueId}
                      onChange={(e) => updateSingle("issueId", e.target.value)}
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}></div>
                  <div className={styles.headersText}> Year Published </div>
                  <div className={styles.contentText}>
                    <TextField
                      id="outlined-basic"
                      label="Year Published"
                      variant="outlined"
                      value={magazine?.yearPublished}
                      onChange={(e) =>
                        updateSingle("yearPublished", e.target.value)
                      }
                    />
                  </div>
                  <div style={{ marginTop: "20px" }}></div>
                  <div className={styles.headersText}> Edition </div>
                  <div className={styles.contentText}>
                    <TextField
                      id="outlined-basic"
                      label="Edition"
                      variant="outlined"
                      value={magazine?.edition}
                      onChange={(e) => updateSingle("edition", e.target.value)}
                    />
                  </div>
                </Grid>
                <Grid item md={6} lg={6} xl={6} key="date-key">
                  <div className={styles.headersText}>
                    {" "}
                    Magazine Cover Image
                  </div>
                  <div>
                    <Button
                      variant="contained"
                      component="label"
                      className={styles.audioButton}
                    >
                      <CloudUploadIcon style={{ marginRight: "7px" }} />{" "}
                      Magazine Cover Image
                      <input
                        type="file"
                        hidden
                        onChange={uploadMagazineImage}
                        accept="image/png, image/gif, image/jpeg"
                      />
                    </Button>
                  </div>
                  <div style={{ marginTop: "30px" }}>
                    <img
                      src={magazine?.coverImage}
                      alt="magazine-cover"
                      height={280}
                      width={280}
                    ></img>
                  </div>
                </Grid>
              </Grid>

              <Grid container spacing={2} key="publish-row">
                <Button
                  onClick={publishMagazine}
                  style={{ margin: "30px auto 0" }}
                >
                  Publish
                </Button>
              </Grid>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
