import { axiosInstance } from "../redux/axiosBase/axiosInstance";

export const checkEmail = (email, cb) => {
  if (email) {
    let check = email
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
    cb(check ? true : false);
    return check;
  }
};

export const checkOTP = (otp, cb) => {
  if (otp) {
    let check = otp.match(/^\d{4}$/);
    cb(check ? true : false);
    return check;
  }
};

export const getCookie = (cname) => {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) === 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

export const setCookie = (name, value, days = 1) => {
  let expires = "";
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires=${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ""}${expires}; path=/`;
};

export const formatDatetoTzFormat = (date) => {
  // date should be in format -> "20200102" // yyyymmdd
  if (date) {
    let newDate = date
      ?.toString()
      ?.replace(/(\d{4})(\d{2})(\d{2})/g, "$1-$2-$3");
    return new Date(newDate);
  }
};

export const formatDateToyyyymmddFormat = (date) => {
  // date should be in format -> "Fri Apr 05 2019 16:59:00 GMT-0700 (Pacific Daylight Time)"
  var event = new Date(date);
  return JSON.stringify(event).slice(1, 11).replaceAll("-", "");
};

export const convertToReadableDate = (date) => {
  // date should be in format -> "20200102" // yyyymmdd
  if (date) {
    let newDate = date
      ?.toString()
      ?.replace(/(\d{4})(\d{2})(\d{2})/g, "$1-$2-$3");
    return new Date(newDate).toDateString();
  }
};

export const convertYYYYMMDDhhmmDateToReadableDate = (date) => {
  return `${date.slice(0, 4)}-${date.slice(4, 6)}-${date.slice(6, 8)}T${date.slice(8, 10)}:${date.slice(10, 12).length > 1 ? date.slice(10, 12) : `0${date.slice(10, 12)}` }`;
}

export const extractDate = (date) => {
  // date should be in format -> "202001020403" yyyymmddhhmm
  return date ? date?.toString()?.slice(0, 8) : "";
};

export const extractTime = (date) => {
  // date should be in format -> "202001020403" yyyymmddhhmm
  return date ? date?.toString()?.slice(8) : "";
};

export const getdate = (date) => {
  // date should be in format -> "202001020403" --> yyyymmddhhmm
  return date ? date?.toString()?.slice(6, 8) : "";
};

export const getMonth = (date) => {
  // date should be in format -> "202001020403" --> yyyymmddhhmm
  const month = date?.toString()?.slice(4, 6);
  switch (month) {
    case "01":
      return "Jan";
    case "02":
      return "Feb";
    case "03":
      return "Mar";
    case "04":
      return "Apr";
    case "05":
      return "May";
    case "06":
      return "Jun";
    case "07":
      return "Jul";
    case "08":
      return "Aug";
    case "09":
      return "Sep";
    case "10":
      return "Oct";
    case "11":
      return "Nov";
    case "12":
      return "Dec";
    default:
      return "";
  }
};

export const formatAMPM = (date) => {
  // date shold be in --> "0700" // hhmm
  let hours = date?.slice(0, 2);
  let minutes = date?.slice(2);
  let ampm = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;
  const strTime = hours + ":" + minutes + " " + ampm;
  return strTime;
};

export const isEmtpty = (value) => {
  return (
    value === null || // check for null
    value === undefined || // check for undefined
    value === "" || // check for empty string
    value === false ||
    (Array.isArray(value) && value.length === 0) || // check for empty array
    (typeof value === "object" && Object.keys(value).length === 0) // check for empty object
  );
};

export const convertDateTimeFormatObjectTOTime = ({ date, time }) => {
  // combining the date
  let newDate = Object.values(date).map((value, index, date) => {
    if ([1, 2].includes(index)) {
      if (date.length - 1 === index) {
        if (value < 10) return "0" + value;
        else return value;
      } else if (value < 10) return "0" + value + "-";
      else return value + "-";
    } else {
      return value + "-";
    }
  });
  newDate.push("T");
  const combinedDate = newDate.join("");

  // combining the time
  const newTime = Object.values(time).map((value, index, time) => {
    if (time.length - 2 === index) {
      if (value < 10) return "0" + value;
      else return value;
    } else if (value < 10) return "0" + value + ":";
    else return value + ":";
  });
  newTime.pop();
  const combinedTime = newTime.join("");

  return combinedDate + combinedTime;
};

export const parseDataTimeToObjectFormat = (str) => {
  const [date, time] = str.split("T");
  const [year, month, day] = date.split("-");
  const [hour, minute, second] = time.split(":");

  const dateTimeFormat = {
    date: { year: Number(year), month: Number(month), day: Number(day) },
    time: {
      hour: Number(hour),
      minute: Number(minute),
      second: Number(second),
    },
  };

  return dateTimeFormat;
};

export const groupBy = (items, key) =>
  items.reduce(
    (result, item) => ({
      ...result,
      [item[key]]: [...(result[item[key]] || []), item],
    }),
    {}
  );

export const getPodDetails = async (id) => {
  const response = await axiosInstance?.get(`/api/podcast/get?podcastId=${id}`);
  return response?.data?.data;
};
