import "firebase/firestore";
import { initializeApp } from "firebase/app";
import "firebase/compat/auth";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAxOS3GM36-aGRF2_p9X2zlmUoQshubE4E",
  authDomain: "jeevitam--dashboard.firebaseapp.com",
  databaseURL: "https://jeevitam--dashboard-default-rtdb.firebaseio.com",
  projectId: "jeevitam--dashboard",
  storageBucket: "jeevitam--dashboard.appspot.com",
  messagingSenderId: "288116762320",
  appId: "1:288116762320:web:aa235516a33d99fe9560d5",
  measurementId: "G-3YXDP05ZNF",
};

// Initialize Firebase
export const app = initializeApp(firebaseConfig);
export const firebaseAuth = getAuth(app);
export const storage = getStorage(app);
