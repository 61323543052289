import React from "react";
import styles from "./podcastContainer.module.scss";

export const RenderRowCardPodcast = React.memo(
  ({ podcastList, index, activepodcast, selectCell, keyForCard, style }) => {
    return (
      <div key={`row-card-${keyForCard}`} className={``} style={style}>
        <div
          className={`${styles.podcastCard} ${
            activepodcast ? styles.activepodcast : ""
          }`}
          role="button"
          onClick={() => {
            selectCell(index, podcastList[index]);
          }}
        >
          <div className={styles.podcastCircle}>
            <div style={{ paddingTop: "10px" }}>
              {" "}
              {podcastList[index]?.day}{" "}
            </div>
            <div> {podcastList[index]?.month}</div>
          </div>

          <div className={styles.podcastDetails}>
            <div
              className={styles.titleText}
            >{`Episode -${podcastList[index]?.episodeId}`}</div>
            <div
              className={styles.secondaryTitleText}
              title={podcastList[index]?.title}
            >
              {podcastList[index]?.title}
            </div>
            <div
              className={styles.descText}
              title={podcastList[index]?.description}
            >
              {podcastList[index]?.description}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
