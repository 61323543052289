import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Sidebar from "../SideBar/Sidebar";
import styles from "./layout.module.scss";

export default function Layout() {
  return (
    <>
      <Header />
      <div className={styles.layoutRest}>
        <div className={styles.layoutSidebar}>
          <Sidebar />
        </div>
        <div id="layoutChildren" className={styles.layoutChildren}>
          <Outlet />
        </div>
      </div>
    </>
  );
}
