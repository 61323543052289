import React from "react";
import { useLocation } from "react-router-dom";
import LoginForm from "./LoginForm";
import logo from "../../static/images/jeevitam.png";
import styles from "./login.module.scss";

export default function Login() {
  const location = useLocation();

  let from = location.state?.from?.pathname || "/";

  return (
    <div className={styles.wrapper}>
      <div className={styles.loginContainer}>
        <img alt="Jeevitam Login" src={logo} className={styles.logoImage} />
        <LoginForm from={from} />
      </div>
    </div>
  );
}
