import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import styles from "./sidebar.module.scss";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import ImportContactsIcon from "@mui/icons-material/ImportContacts";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import DashBoardIcon from "@mui/icons-material/Dashboard";

export default function Sidebar() {
  let location = useLocation();
  const navigate = useNavigate();
  const [isExpanded, setIsExpanded] = useState(false);
  const handleDivClicked = (path) => {
    navigate(path);
  };

  return (
    <>
      <div
        className={`${styles.sidebarContainer} ${
          isExpanded ? "sidebarContainer-expanded" : "sidebarContainer-closed"
        }`}
      >
        <div
          className="burgerMenu"
          onClick={() => setIsExpanded(!isExpanded)}
        ></div>
        <div
          className={`${styles.linkContainer} ${
            location.pathname === "/" ? `${styles.active}` : ""
          }`}
          onClick={() => handleDivClicked("/")}
        >
          <Link to="/">
            <div style={{ display: "flex" }} className="sidebarTitle">
              <DashBoardIcon fontSize="medium" /> <span> Dashboard </span>
            </div>
          </Link>
        </div>
        <div
          className={`${styles.linkContainer} ${
            location.pathname.includes("/podcast") ? `${styles.active}` : ""
          }`}
          onClick={() => handleDivClicked("/podcast")}
        >
          <Link to="/podcast">
            <div style={{ display: "flex" }} className="sidebarTitle">
              <PodcastsIcon fontSize="medium" /> <span> Podcasts </span>
            </div>
          </Link>
        </div>
        <div
          className={`${styles.linkContainer} ${
            location.pathname.includes("/magazine") ? `${styles.active}` : ""
          }`}
          onClick={() => handleDivClicked("/magazine")}
        >
          <Link to="/magazine">
            <div style={{ display: "flex" }} className="sidebarTitle">
              <ImportContactsIcon fontSize="medium" /> <span> Magazines </span>
            </div>
          </Link>
        </div>
        <div
          className={`${styles.linkContainer} ${
            location.pathname.includes("/event") ? `${styles.active}` : ""
          }`}
          onClick={() => handleDivClicked("/event")}
        >
          <Link to="/">
            <div style={{ display: "flex" }} className="sidebarTitle">
              <CalendarMonthOutlinedIcon fontSize="medium" />{" "}
              <span> Events </span>
            </div>
          </Link>
        </div>
        <div
          className={`${styles.linkContainer} ${
            location.pathname.includes("/books") ? `${styles.active}` : ""
          }`}
          onClick={() => handleDivClicked("/books")}
        >
          <Link to="/books">
            <div style={{ display: "flex" }} className="sidebarTitle">
              <LibraryBooksIcon fontSize="medium" /> <span> Books </span>
            </div>
          </Link>
        </div>
      </div>
    </>
  );
}
