import { axiosInstance } from "../axiosBase/axiosInstance";

const chatUrl = "/api/contact/list";

const getchatList = async (options) => {
  const { data: list } = await axiosInstance.get(chatUrl, { params: options });
  return {
    chatList: list,
  };
};

const chatService = {
  getchatList,
};

export default chatService;
