import React, { useMemo, useState } from "react";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import { Grid, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import moment from "moment";

import styles from "../Podcast/podcastContainer.module.scss";
import CustomButton from "../../reusable/Button/Button";
import { isEmtpty } from "../../../utils/helper";
import { axiosInstance } from "../../../redux/axiosBase/axiosInstance";
import { CustomAlert } from "../../reusable/Alerts/Alert";
import { useDispatch, useSelector } from "react-redux";
import { updateEventList } from "../../../redux/eventReducer/eventSlice";
import { DateTimePicker } from "../../DatePicker";

export const CreateNewEvent = () => {
  const initialState = useMemo(() => ({
    startDate: new Date(),
    liveStream: false,
    eventType: "offline",
    eventName: "",
    mapCo: "",
    eventAddress: "",
    liveStreamLink: "",
    duration: "",
    description: "",
  }), []);

  const [event, setEvent] = useState(initialState);

  const [alertMessage, setAlertMessage] = useState(false);
  const eventState = useSelector((state) => state.events);
  const { eventList = [], totalSize = 0, nextOffset = 0 } = eventState || {};
  const dispatch = useDispatch();

  const updateValues = (field, value) => {
    const tempEvent = { ...event };
    tempEvent[field] = value;
    setEvent(tempEvent);
  }

  const handleCreateEvent = async () => {
    if (
      isEmtpty(event.eventName) ||
      isEmtpty(event.eventType) ||
      isEmtpty(event.eventAddress) ||
      isEmtpty(event.duration) ||
      isEmtpty(event.description)
    ) {
      alert("Please Fill the mandatory fields");
      return;
    }

    let newEvent = {
      title: event.eventName,
      description: event.description,
      yyyymmddhhmm: moment(event.startDate).format('YYYYMMDDHHmm'),
      minutesD: event.duration,
      eventType: event.eventType,
      locationUrl: event.mapCo,
      isLiveStream: event.liveStream,
      address: event.eventAddress,
      streamUrl: event.liveStreamLink,
    };

    const response = await axiosInstance.post("/api/meeting/add", newEvent);
    dispatch(
      updateEventList({
        eventList: [{ ...newEvent, meetingId: new Date().getTime()}, ...eventList],
        totalSize: totalSize + 1,
        nextOffset: nextOffset,
      })
    );
    setAlertMessage(response?.data?.success);
  };

  const handleCancelEvent = () => {
    setEvent(initialState);
  };

  return (
    <div>
      {alertMessage && (
        <CustomAlert
          message={
            alertMessage ? "Event Added Successfully" : " Something Went Wrong"
          }
          success={alertMessage}
        />
      )}
      <div>
        <h1 className={styles.createHeaderext}>
          {" "}
          <span>
            <CalendarMonthOutlinedIcon fontSize="large" />{" "}
          </span>{" "}
          Create New Event
        </h1>
      </div>
      <Grid container spacing={2} key="title-row">
        <Grid item xs={5} md={5} lg={6} key="file-name">
          <div className={styles.headersText}> Event Name</div>
          <div className={styles.contentText}>
            <TextField
              id="e-name"
              label="Event Name"
              variant="outlined"
              required
              className={styles.titleBox}
              value={event.eventName}
              onChange={(e) => updateValues('eventName', e.target.value)}
            />
          </div>
        </Grid>
        <Grid item md={3} lg={3} xl={2} key="episode-number">
          <div className={styles.headersText}> Event Type</div>
          <div>
            <Select
              id="e-type-select"
              value={event.eventType}
              required
              label="Event Type"
              onChange={(e) => updateValues('eventType', e.target.value)}
            >
              <MenuItem value={"offline"} key={"off"}>
                Offline
              </MenuItem>
              <MenuItem value={"online"} key={"on"}>
                Online
              </MenuItem>
            </Select>
          </div>
        </Grid>
        <Grid item md={3} lg={3} xl={4} key="date-key-event">
          <div className={styles.headersText}> Date</div>
          <div>
            <DateTimePicker
              label="Date"
              value={event.startDate}
              setValue={(e) => updateValues('startDate', e)}
              showClearIcon
            />
          </div>
        </Grid>
      </Grid>

      <div className={styles.titleBox}>
        <Grid container spacing={2} key="time-row">
          <Grid item xs={3} md={3} lg={3} key="time">
            <div className={styles.headersText}> 
              <Tooltip title="Duration of event in minutes" placement="top-start">
                <span> Duration </span>
              </Tooltip>
            </div>
            <div className={styles.contentText}>
              <TextField
                id="time-event"
                label="Duration"
                variant="outlined"
                placeholder="Duration in minutes"
                className={styles.titleBox}
                value={event.duration}
                required
                onChange={(e) => updateValues('duration', e.target.value)}
              />
            </div>
          </Grid>
          <Grid item md={9} lg={9} xl={9} key="event-address">
            <div className={styles.headersText}>
              {event.eventType === "offline" ? "Event Address" : "Event Platform"}
            </div>
            <div>
              <TextField
                id="e-address"
                label={
                  event.eventType === "offline" ? "Event Address" : "Event Platform"
                }
                variant="outlined"
                className={styles.titleBox}
                required
                value={event.eventAddress}
                onChange={(e) => updateValues('eventAddress', e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={styles.titleBox}>
        <Grid container spacing={2} key="map-row">
          <Grid item xs={3} md={3} lg={3} key="map-co-ordinates">
            <div className={styles.headersText}>
              {event.eventType === "offline" ? "Location Url" : "Event Link"}
            </div>
            <div className={styles.contentText}>
              <TextField
                id="map-cordinates"
                label={
                  event.eventType === "offline" ? "Location Url" : "Event Link"
                }
                variant="outlined"
                className={styles.titleBox}
                value={event.mapCo}
                onChange={(e) => updateValues('mapCo', e.target.value)}
              />
            </div>
          </Grid>
          <Grid item xs={9} md={9} lg={9} key="event-desc">
            <div className={styles.headersText}>Description</div>
            <div className={styles.contentText}>
              <TextField
                id="event-desc"
                label={"Description"}
                variant="outlined"
                className={styles.titleBox}
                value={event.description}
                multiline={true}
                rows={3}
                onChange={(e) => updateValues('description', e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      {event.eventType === "offline" && (
        <div className={styles.titleBox}>
          <Grid container spacing={2} key="link-row">
            <Grid item xs={3} md={3} lg={3} key="link-yes-no">
              <div className={styles.headersText}> Live Stream</div>
              <div className={styles.contentText}>
                <Select
                  id="live-select"
                  value={event.liveStream}
                  label="LiveStream"
                  onChange={(e) => updateValues('liveStream', e.target.value)}
                >
                  <MenuItem value={true} key={"yes-live"}>
                    Yes
                  </MenuItem>
                  <MenuItem value={false} key={"no-live"}>
                    No
                  </MenuItem>
                </Select>
              </div>
            </Grid>
            <Grid item md={7} lg={7} xl={7} key="event-address">
              <div className={styles.headersText}> Live Stream Link</div>
              <div>
                <TextField
                  id="live-link"
                  label="Live Stream Link"
                  variant="outlined"
                  className={styles.titleBox}
                  value={event.liveStreamLink}
                  required={event.liveStream}
                  onChange={(e) => updateValues('liveStreamLink', e.target.value)}
                />
              </div>
            </Grid>
          </Grid>
        </div>
      )}

      <div className={styles.buttonRow}>
        <CustomButton
          className={styles.btn}
          onClick={() => {
            handleCancelEvent();
          }}
          text="Cancel"
          title="Cancel"
        />
        <CustomButton
          className={styles.podcastBtn}
          onClick={() => {
            handleCreateEvent();
          }}
          text="Add Event"
          title="Add Event"
        />
      </div>
    </div>
  );
};
