import React, { useState, useEffect } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import ClearIcon from "@mui/icons-material/Clear";
import styles from "./podcastContainer.module.scss";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebaseConfig";

var a;
const AudioPlay = ({ fileUrl, setfileUrl }) => {
  const [buttonName, setButtonName] = useState("Play");
  const [audio, setAudio] = useState();
  const [filename, setFileName] = useState("");

  useEffect(() => {
    if (a) {
      a.pause();
      a = null;
      setButtonName("Play");
    }
    if (audio) {
      a = new Audio(audio);
      a.onended = () => {
        setButtonName("Play");
      };
    }
  }, [audio]);

  const handleClick = () => {
    if (buttonName === "Play") {
      a.play();
      setButtonName("Pause");
    } else {
      a.pause();
      setButtonName("Play");
    }
  };

  const addFile = (e) => {
    let file = e.target.files[0];
    if (!file) {
      alert("Please choose a file first!");
    }
    if (file && filename === "") {
      setAudio(URL.createObjectURL(file));
      setFileName(file.name);
      const storageRef = ref(storage, `podcasts/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask?.snapshot?.ref)?.then((url) => {
            setfileUrl(url);
          });
        }
      );
    }
  };

  const clearAudio = (e) => {
    setAudio("");
    setFileName("");
    setfileUrl("");
    e.preventDefault();
  };

  return (
    <div>
      <button onClick={handleClick} className={styles.play}>
        {buttonName === "Play" ? <PlayArrowIcon /> : <PauseIcon />}{" "}
      </button>
      <label htmlFor="files" className={styles.audioButton}>
        <CloudUploadIcon style={{ marginRight: "7px" }} />
        {filename ? (
          <>
            {" "}
            {filename}{" "}
            <button onClick={(e) => clearAudio(e)}>
              <ClearIcon style={{ marginLeft: "7px" }} />
            </button>{" "}
          </>
        ) : (
          " Upload Audio File "
        )}
      </label>

      <input
        id="files"
        type="file"
        hidden
        onChange={addFile}
        accept="audio/mp3,audio/*;capture=microphone"
      />
    </div>
  );
};

export default AudioPlay;
