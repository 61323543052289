/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getPodcastlist } from "../../../redux/podcastReducer/podcastSlice";
import { CreateNewPodcast } from "./CreateNewPodcast";
import { ParticularPodcast } from "./ParticularPodcast";
import styles from "./podcastContainer.module.scss";
import { PodcastSideBar } from "./PodcastSideBar";

export const PodcastContainer = React.memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const podcastState = useSelector((state) => state.podcasts);
  const { podcastList = [] } = podcastState || {};
  const [newPodcastScreen, setNewPodcastScreen] = useState(true);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const podcastId = id;
  const dispatch = useDispatch();
  const [sidebarData, setSideBarData] = useState([]);
  const [selectedPodcast, setSelectedPodcast] = useState();

  useEffect(() => {
    if (window.location.href.includes("create-podcast")) {
      setNewPodcastScreen(true);
    } else {
      setNewPodcastScreen(false);
    }
    dataToPassToSidebar();
  }, [location, sidebarData?.length]);

  const onClickPodcast = (data) => {
    navigate(`/podcast/${data.podcastId.toString()}`);
  };

  const dataToPassToSidebar = () => {
    if (!podcastList.length) {
      initialMapSetup();
    } else {
      setInnerView(podcastList);
    }
  };

  const setInnerView = (data) => {
    const allData = [...data];
    const filteredData = allData?.filter((pod) => pod?.podcastId === podcastId);
    setSelectedPodcast(filteredData?.length > 0 ? filteredData[0] : []);
    setSideBarData(podcastList);
  };

  const initialMapSetup = async () => {
    try {
      setLoading(true);
      const response = await dispatch(getPodcastlist());
      setSideBarData(response.payload.podcastlist);
      setInnerView(response.payload.podcastlist);
    } catch (e) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.podcastContainer}>
      {loading ? (
        <>loading</>
      ) : (
        <>
          <div className={styles.podcastSideBar}>
            <PodcastSideBar
              onClick={onClickPodcast}
              podcastData={sidebarData}
              podcastId={podcastId}
            />
          </div>
          <div className={styles.podcastContent}>
            {newPodcastScreen ? (
              <CreateNewPodcast />
            ) : (
              <ParticularPodcast
                key={selectedPodcast?.podcastId}
                selectedPodcast={selectedPodcast}
                setSelectedPodcast={setSelectedPodcast}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
});

export default PodcastContainer;
