import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { updateUser } from "../../redux/auth/authSlice";
import { checkEmail } from "../../utils/helper";
import CustomButton from "../reusable/Button/Button";
import { signInWithEmailAndPassword } from "firebase/auth";
import styles from "./loginForm.module.scss";
import { firebaseAuth } from "../../firebaseConfig";
import { CustomAlert } from "../reusable/Alerts/Alert";

export default function LoginForm({ from }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [showError, setShowError] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEmailInput = (e) => {
    setShowError(false);
    checkEmail(e.target.value, setIsEmailValid);
    setEmail(e.target.value);
  };

  const handlePasswordInput = (e) => {
    setShowError(false);
    setPassword(e.target.value);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const auth = firebaseAuth;

    try {
      const resp = await signInWithEmailAndPassword(auth, email, password);
      const token = await resp?.user?.getIdToken({ forceRefresh: true });
      if (resp?.user) {
        localStorage.setItem("userObj", resp.user);
        localStorage.setItem("UID", resp.user?.uid);
        localStorage.setItem("userEmail", resp.user?.email);
        localStorage.setItem("auth-token", token);
        dispatch(updateUser(JSON.parse(JSON.stringify(resp?.user))));
        navigate("/");
      }
    } catch (e) {
      console.log(e.toString());
      setShowError(true);
    }
  };

  return (
    <>
      {showError && <CustomAlert message={"Wrong Password"} success={false} />}
      <section className={styles.formContainer}>
        <form action="" onSubmit={() => {}}>
          <header>
            <h2 className={styles.loginHead}>Login</h2>
            <p className={styles.subHeading}>
              Welcome , Please Enter Email and Password
            </p>
          </header>

          <div className={styles.emailInputContainer}>
            <p className={styles.emailTitle}>Email</p>
            <input
              className={styles.emailInput}
              type="email"
              placeholder="Enter your email"
              value={email}
              onChange={(e) => handleEmailInput(e)}
            />
            <br />
            <p className={styles.emailTitle}>Password</p>
            <input
              className={styles.emailInput}
              type="password"
              placeholder="Enter your Password"
              value={password}
              onChange={(e) => handlePasswordInput(e)}
            />
            <p className={styles.errorMessage}>
              {showError && email ? "Enter Email/Password does not match" : " "}
            </p>
          </div>
          <CustomButton
            theme="primary"
            type="submit"
            className={styles.otpButton}
            onClick={(e) => handleLogin(e)}
            text="Login"
            title="Login"
          />
          <p className={styles.quesText}>
            Trouble Logging in?&nbsp;
            <a href={`mailto:rohitsharma@91social.com?subject="subject text"`}>
              Contact Admin
            </a>
          </p>
        </form>
      </section>
    </>
  );
}
