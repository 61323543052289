/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getMagazineList } from "../../../redux/magazineReducer/magazineSlice";
import styles from "../Podcast/podcastContainer.module.scss";
import { CreateNewArticle } from "./CreateNewArticle";
import { MagazineSidebar } from "./MagazineSidebar";
import { ParticularArticle } from "./ParticularArticle";

export const MagazineContainer = React.memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const magzineState = useSelector((state) => state.magazines);
  const { magazineList = [] } = magzineState || {};
  const [newMagazineScreen, setNeMagazineScreen] = useState(true);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const articleId = id;
  const dispatch = useDispatch();
  const [sidebarData, setSideBarData] = useState([]);
  const [selectedArticle, setSelectedArticle] = useState();

  useEffect(() => {
    if (window.location.href.includes("create-article")) {
      setNeMagazineScreen(true);
    } else {
      setNeMagazineScreen(false);
    }
    dataToPassToSidebar();
  }, [location, sidebarData]);

  const onClickArticle = (data) => {
    navigate(
      `/magazine/${data?.id?.toString() || data?.articleId?.toString()}`
    );
  };

  const dataToPassToSidebar = () => {
    if (!magazineList.length) {
      initialMapSetup();
    } else {
      setInnerView(magazineList);
    }
  };

  const setInnerView = (data) => {
    const allData = [...data];
    const filteredData = allData?.filter(
      (item) => (item?.articleId || item?.id) === articleId
    );

    setSelectedArticle(filteredData?.length > 0 ? filteredData[0] : []);
    setSideBarData(magazineList);
  };

  const initialMapSetup = async () => {
    try {
      setLoading(true);
      const response = await dispatch(getMagazineList());
      setSideBarData(response.payload.magazineList);
      setInnerView(response.payload.magazineList);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.podcastContainer}>
      {loading ? (
        <>loading</>
      ) : (
        <>
          <div className={styles.podcastSideBar}>
            <MagazineSidebar
              onClick={onClickArticle}
              articleData={sidebarData}
              articleId={articleId}
            />
          </div>
          <div className={styles.podcastContent}>
            {newMagazineScreen ? (
              <CreateNewArticle />
            ) : (
              <ParticularArticle
                key={selectedArticle.articleId}
                selectedArticle={selectedArticle}
                setSelectedArticle={setSelectedArticle}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
});

export default MagazineContainer;
