import React from "react";
import styles from "../Podcast/podcastContainer.module.scss";

export const ParticularArticleDetails = React.memo(({ currentArticle }) => {
  return (
    <div className={styles.mobileDeviceCenter}>
      <div className={styles.articleDesign}>
        <div className={styles.articleHead}>{currentArticle?.title}</div>
        <div className={styles.articleSubHead}>
          <div>
            {currentArticle?.pubDate} | {currentArticle?.author}
          </div>
        </div>
        <div className={styles.articleImg}>
          <img
            src={currentArticle?.imageUrl}
            alt="article"
            height={300}
            width={410}
          />
        </div>
        <div className={styles.articleDesc}>{currentArticle?.content}</div>
      </div>
    </div>
  );
});
