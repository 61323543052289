import React, { useMemo, useState } from "react";
import { Button, Grid, MenuItem, Select, TextField } from "@mui/material";
import styles from "../Podcast/podcastContainer.module.scss";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CustomButton from "../../reusable/Button/Button";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { isEmtpty, formatDateToyyyymmddFormat } from "../../../utils/helper";
import { axiosInstance } from "../../../redux/axiosBase/axiosInstance";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebaseConfig";
import { CustomAlert } from "../../reusable/Alerts/Alert";
import { useDispatch, useSelector } from "react-redux";
import { updateBookList } from "../../../redux/bookReducer/bookSlice";

export const CreateNewBook = () => {
  const initialBookState = useMemo(() => ({
    title: "",
    bookAuthor: "",
    bookImageUrl: "",
    description: "",
    buyOnAmazon: false,
    amazonUrl: "",
    buyOnFlipkart: false,
    flipkartUrl: "",
  }), [])

  const [newBookState, setNewBookState] = useState(initialBookState);
  const [alertMessage, setAlertMessage] = useState(false);
  const dispatch = useDispatch();
  const bookState = useSelector((state) => state.books);
  const { bookList = [], totalSize = 0, nextOffset = 0 } = bookState || {};

  const uploadBookImage = (e) => {
    let file = e.target.files[0];
    if (!file) {
      alert("Please choose a file first!");
    }
    if (file) {
      const storageRef = ref(storage, `/books/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask?.snapshot?.ref)?.then((url) => {
            updateSingle("bookImageUrl", url);
          });
        }
      );
    }
  };

  const updateSingle = (key, value) => {
    let newdata = { ...newBookState };
    newdata[key] = value;
    setNewBookState(newdata);
  };

  const handleCancel = () => {
    //clears all State
    setNewBookState({ ...initialBookState });
  };

  const handleCreateNew = async () => {
    const {
      title,
      bookAuthor,
      bookImageUrl,
      description,
      buyOnAmazon,
      amazonUrl,
      buyOnFlipkart,
      flipkartUrl,
    } = newBookState;
    //to - do in backend also
    if (
      isEmtpty(title) ||
      isEmtpty(bookAuthor) ||
      isEmtpty(bookImageUrl) ||
      isEmtpty(description)
    ) {
      alert("Please Fill the mandatory fields");
      return;
    }

    let newBook = {
      title,
      authorId: "4bd5a469-6591-4446-9fa4-ce946aee2312",
      imageUrl: bookImageUrl,
      buyOnAmazon,
      amazonUrl,
      buyOnFlipkart,
      flipkartUrl,
      description,
    };

    const response = await axiosInstance.post("/api/book/add", newBook);
    setAlertMessage(response?.data?.success);
    dispatch(
      updateBookList({
        bookList: [response?.data?.data, ...bookList],
        totalSize: totalSize,
        nextOffset: nextOffset,
      })
    );
  };

  return (
    <div>
      {alertMessage && (
        <CustomAlert
          message={
            alertMessage ? "Book Added Successfully" : " Something Went Wrong"
          }
          success={alertMessage}
        />
      )}
      <div>
        <h1 className={styles.createHeaderext}>
          <span>
            <LibraryBooksIcon fontSize="large" />
          </span>
          Add New Book
        </h1>
      </div>
      <Grid container spacing={2} key="book-title-row">
        <Grid item xs={4} md={4} lg={4} key="book-name">
          <div className={styles.headersText}> Book Name</div>
          <div className={styles.contentText}>
            <TextField
              id="outlined-basic"
              label="Book Name"
              variant="outlined"
              required
              value={newBookState?.title}
              onChange={(e) => updateSingle("title", e.target.value)}
            />
          </div>
        </Grid>
        <Grid item md={4} lg={4} xl={4} key="book-author">
          <div className={styles.headersText}> Author</div>
          <div>
            {" "}
            <TextField
              id="outlined-basic"
              label="Author"
              variant="outlined"
              value={newBookState?.bookAuthor}
              required
              onChange={(e) => updateSingle("bookAuthor", e.target.value)}
            />
          </div>
        </Grid>
        <Grid item md={3} lg={3} xl={4} key="book-cover">
          <div className={styles.headersText}> Book Cover Image</div>
          <div>
            <Button
              variant="contained"
              component="label"
              className={styles.audioButton}
              onChange={(e) => updateSingle("bookImageUrl", e.target.value)}
            >
              <CloudUploadIcon style={{ marginRight: "7px" }} /> Upload Book
              Image
              <input
                type="file"
                hidden
                onChange={uploadBookImage}
                accept="image/png, image/gif, image/jpeg"
              />
            </Button>
          </div>
        </Grid>
      </Grid>

      <div className={styles.titleBox}>
        <div className={styles.headersText}>Description</div>
        <TextField
          id="outlined-basic"
          label="Description"
          variant="outlined"
          className={styles.titleBox}
          multiline={true}
          required
          rows={8}
          value={newBookState?.description}
          onChange={(e) => updateSingle("description", e.target.value)}
        />
      </div>

      <div className={styles.titleBox}>
        <Grid container spacing={2} key="sale-amazon-row">
          <Grid item xs={3} md={3} lg={3} key="amazon">
            <div className={styles.headersText}> Sale on Amazon</div>
            <div className={styles.contentText}>
              <Select
                id="live-select"
                value={newBookState?.buyOnAmazon ? "yes" : "no"}
                label="LiveStream"
                onChange={(e) =>
                  updateSingle(
                    "buyOnAmazon",
                    e.target.value === "yes" ? true : false
                  )
                }
              >
                <MenuItem value={"yes"} key={"yes-amazon"}>
                  Yes
                </MenuItem>
                <MenuItem value={"no"} key={"no-amazon"}>
                  No
                </MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item md={9} lg={9} xl={9} key="amazon-address">
            <div className={styles.headersText}>Amazon Link</div>
            <div>
              <TextField
                id="e-address"
                label={"Amazon Link"}
                variant="outlined"
                className={styles.titleBox}
                value={newBookState?.amazonUrl}
                required={newBookState?.buyOnAmazon}
                onChange={(e) => updateSingle("amazonUrl", e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={styles.titleBox}>
        <Grid container spacing={2} key="sale-flipkart-row">
          <Grid item xs={3} md={3} lg={3} key="flipkart">
            <div className={styles.headersText}> Sale on Flipkart</div>
            <div className={styles.contentText}>
              <Select
                id="live-select"
                value={newBookState?.buyOnFlipkart ? "yes" : "no"}
                label="LiveStream"
                onChange={(e) =>
                  updateSingle(
                    "buyOnFlipkart",
                    e.target.value === "yes" ? true : false
                  )
                }
              >
                <MenuItem value={"yes"} key={"yes-flipkart"}>
                  Yes
                </MenuItem>
                <MenuItem value={"no"} key={"no-flipkart"}>
                  No
                </MenuItem>
              </Select>
            </div>
          </Grid>
          <Grid item md={9} lg={9} xl={9} key="flip-link">
            <div className={styles.headersText}>Flipkart Link</div>
            <div>
              <TextField
                id="e-address"
                label={"Flipkart Link"}
                variant="outlined"
                className={styles.titleBox}
                value={newBookState?.flipkartUrl}
                required={newBookState?.buyOnFlipkart}
                onChange={(e) => updateSingle("flipkartUrl", e.target.value)}
              />
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={styles.buttonRow}>
        <CustomButton
          className={styles.btn}
          onClick={() => {
            handleCancel();
          }}
          text="Cancel"
          title="Cancel"
        />
        <CustomButton
          className={styles.podcastBtn}
          onClick={() => {
            handleCreateNew();
          }}
          text="Add Book"
          title="Add Book"
        />
      </div>
    </div>
  );
};
