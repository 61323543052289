import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import dashBoardService from "./dashBoardService";

export const getDashBoardDataSlice = createAsyncThunk(
  "dashboard/getAllData",
  async (options, thunkAPI) => {
    try {
      let listings = await dashBoardService.getDashBoardData(options);
      return listings;
    } catch (error) {
      const message = error.response.data._embedded.errors[0].message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  dashBoardData: [],
  isLoading: false,
  isError: false,
};

const dashBoardData = createSlice({
  name: "dashBoardList",
  initialState,
  reducers: {
    reset: (state) => {
      state.dashBoardData = [];
      state.isError = false;
      state.error = "";
    },
    resetError: (state) => {
      state.isError = false;
      state.error = "";
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getDashBoardDataSlice.pending, (state) => {
        state.isLoading = true;
        state.dashBoardData = [];
        state.isError = false;
        state.error = "";
      })
      .addCase(getDashBoardDataSlice.fulfilled, (state, action) => {
        state.isLoading = false;
        state.dashBoardData = action.payload.dashBoardSummary;
      })
      .addCase(getDashBoardDataSlice.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { reset, resetError } = dashBoardData.actions;

export default dashBoardData.reducer;
