/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "./DashBoard.module.scss";
import { Grid, Paper, Typography } from "@material-ui/core";
import { podcastData } from "./podcastData";
import PodcastView from "./podcast-view";
import ArticleReaders from "./article-readers";
import { useDispatch, useSelector } from "react-redux";
import { getDashBoardDataSlice } from "../../redux/dashBoardReducer/dashBoardSlice";
import { axiosInstance } from "../../redux/axiosBase/axiosInstance";

const DashBoard = () => {
  const dashboard = useSelector((state) => state.dashboard);
  const { dashboardListData = [] } = dashboard || {};
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [allData, setAllData] = useState([]);
  const [dashboardData, setDashBoardData] = useState();

  useEffect(() => {
    initialData();
  }, []);

  const initialData = async () => {
    if (!dashboardListData?.length) {
      try {
        setLoading(true);
        const response = await dispatch(getDashBoardDataSlice());
        let result = response?.payload?.dashBoardSummary;
        let res = await makeData(result);
        setAllData(res);
      } catch (e) {
      } finally {
        setLoading(false);
      }
    } else {
      setDashBoardData(dashboardData);
    }
  };

  const makeData = async (result) => {
    let arr = [];
    for (let i = 0; i < result.length; i++) {
      if (result[i]?.itemType === "PODCAST") {
        const pod = await getPodDetails(result[i]?.itemId);
        arr.push({ ...result[i], ...pod });
      } else {
        const article = await getArticleDetails(result[i]?.itemId);
        arr.push({ ...result[i], ...article });
      }
    }
    return arr;
  };

  const getPodDetails = async (id) => {
    const response = await axiosInstance?.get(
      `/api/podcast/get?podcastId=${id}`
    );
    return response?.data?.data;
  };

  const getArticleDetails = async (id) => {
    const response = await axiosInstance?.get(
      `/api/magazine/article/get?articleId=${id}`
    );
    return response?.data?.data;
  };

  return (
    <>
      {loading ? (
        "loading"
      ) : (
        <div>
          <Grid container className={styles.dashboardContainer}>
            <Grid item sm={6} xs={12} className={styles.summaryText}>
              <Typography variant="h5"> Summary</Typography>
            </Grid>
            {/* <Grid item sm={3} xs={12} className={styles.buttonGrid}>
              <Button
                style={{ float: "right" }}
                variant="outlined"
                color="secondary"
              >
                01 Dec - 30 Dec 2022
              </Button>
            </Grid>
            <Grid item sm={3} xs={12} className={styles.buttonGrid}>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => window.print()}
              >
                Download Report
              </Button>
            </Grid> */}
          </Grid>
          <Grid container className={styles.podcastContainer}>
            {podcastData(allData)?.map((item) => (
              <Grid
                item
                xs={12}
                sm={3}
                className={styles.podcastItemContainer}
                key={item?.heading}
              >
                <Paper className={styles.paper}>
                  <Grid container className={styles.headingContainer}>
                    <Grid item xs={10} sm={10} className={styles.heading}>
                      {item.heading}
                    </Grid>
                    <Grid item xs={2} sm={2} className={styles.icon}>
                      {item.icon}
                    </Grid>
                  </Grid>
                  <Grid className={styles.titleContainer}>
                    <Typography className={styles.title}>
                      {item.title}
                    </Typography>
                  </Grid>
                  <Grid className={styles.responseContainer}>
                    {item.response}
                  </Grid>
                </Paper>
              </Grid>
            ))}
          </Grid>

          <Grid style={{ margin: "20px 0" }}>
            <Typography variant="h5" className={styles.graphHeading}>
              Podcast Views
            </Typography>
          </Grid>
          <PodcastView />
          <Grid style={{ margin: "20px 0" }}>
            <Typography variant="h5" className={styles.graphHeading}>
              Article Readers
            </Typography>
          </Grid>
          <ArticleReaders />
        </div>
      )}
    </>
  );
};

export default DashBoard;
