import React, { useState, useRef, useEffect } from "react";
import { CellMeasurer, CellMeasurerCache } from "react-virtualized";
import styles from "./podcastContainer.module.scss";
import { RenderRowCardPodcast } from "./RenderRowCardPodcast";
import { VirtualizedList } from "../../reusable/VitualisedList/VirtualisedList";
import { Button, TextField } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance } from "../../../redux/axiosBase/axiosInstance";
import { updatePodcastList } from "../../../redux/podcastReducer/podcastSlice";

export const PodcastSideBar = React.memo(({ onClick, podcastId }) => {
  const calculatedHeight = useRef();
  const [searchByName, searchByNameHandler] = useState("");
  const [scrollToRow, scrollToRowHandler] = useState(-1);
  const [height, setHeight] = useState(window.innerHeight - 285);
  const [allData, setAllData] = useState([]);
  const [scrollDisabled, setScrollDisabled] = useState(true);
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 96,
  });
  const podcastState = useSelector((state) => state.podcasts);
  const { podcastList = [], totalSize = 0, nextOffset = 0 } = podcastState;
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const resizeListener = () => {
      setHeight(window.innerHeight - 285);
    };
    window.addEventListener("resize", resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    setAllData(podcastList);
  }, [podcastList]);

  useEffect(() => {
    setScrollToRow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData]);

  const filteredByNameData = (name) => {
    const searchedData = podcastList?.filter((ins) =>
      ins?.title?.toLowerCase()?.includes(name?.toLowerCase())
    );
    setAllData(searchedData);
  };

  const setScrollToRow = () => {
    const currentIndex = allData.findIndex(
      (data) => data?.podcastId === podcastId
    );
    if (currentIndex !== scrollToRow && currentIndex !== -1) {
      scrollToRowHandler(currentIndex);
    }
  };

  const selectCell = (index, data) => {
    scrollToRowHandler(index);
    onClick(data);
  };

  const renderRow = ({ index, style, scrollToRow, parent }) => {
    const isActive = index === scrollToRow;

    return (
      <div className={""} key={`podcast-list-${index}`}>
        <CellMeasurer
          key={`cell-measurer-report-card-${index}`}
          cache={cache}
          columnIndex={0}
          parent={parent}
          rowIndex={index}
        >
          {() => (
            <RenderRowCardPodcast
              keyForCard={`inner-podcast-row-card-${index}`}
              podcastList={allData}
              index={index}
              style={style}
              activepodcast={isActive ? `${styles.activeRow}` : ""}
              selectCell={(index, data) => {
                selectCell(index, data);
              }}
            />
          )}
        </CellMeasurer>
      </div>
    );
  };

  const handleKeyDown = ({ keyCode }) => {
    let scrollTo = keyCode === 38 ? scrollToRow - 1 : scrollToRow + 1;
    const data = allData[scrollTo];
    if ((keyCode === 38 || keyCode === 40) && data) {
      scrollToRowHandler(() => scrollTo);
      onClick(data);
    }
  };

  const createNewPodcast = () => {
    navigate("/podcast/create-podcast");
  };

  const loadMorepodcasts = async () => {
    const response = await axiosInstance.get(
      `/api/podcast/recent?limit=10&offset=${nextOffset}`
    );
    dispatch(
      updatePodcastList({
        podcastlist: [...podcastList, ...response?.data?.data?.collection],
        totalSize: response?.data?.data?.totalSize,
        nextOffset: response?.data?.data?.nextOffset,
      })
    );
  };

  return (
    <div>
      <div className={styles.createNewBox} onClick={() => createNewPodcast()}>
        <AddCircleOutlineIcon fontSize={"large"} color="inherit" />
        <div className={styles.createText}>Create New Podcast</div>
      </div>
      <div className={styles.upDownPadding}>
        <TextField
          id="search-by-name"
          label="Search Podcast"
          variant="outlined"
          value={searchByName}
          onChange={(e) => {
            searchByNameHandler(e.target.value);
            filteredByNameData(e.target.value);
          }}
          className={styles.widthBox}
        />
      </div>
      <div
        className={styles.heightfilter}
        ref={calculatedHeight}
        onKeyDown={handleKeyDown}
        onMouseOver={() => setScrollDisabled(false)}
        role="button"
      >
        {allData.length === 0 && (
          <div className={styles.noData}>
            <span>{"No Podcast Available"}</span>
          </div>
        )}
        {allData.length > 0 && (
          <>
            <VirtualizedList
              cache={cache}
              cardWidth={300}
              height={height}
              rowCount={allData.length}
              rowRenderer={(params) =>
                renderRow({ scrollToRow: scrollToRow, ...params })
              }
              scrollDisabled={scrollDisabled}
              scrollToRowParent={scrollToRow}
            />
          </>
        )}
      </div>
      {/* pagination goes here  */}
      {totalSize > 9 && (
        <div className={styles.loadMoreBtn}>
          <Button onClick={() => loadMorepodcasts()}>Load More</Button>
        </div>
      )}
    </div>
  );
});
