import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import logo from "../../static/images/jeevitam.png";
import styles from "./header.module.scss";
import CustomButton from "../reusable/Button/Button";
import { logout } from "../../redux/auth/authSlice";
import logoutImg from "../../static/images/Logout.png";
import chatImg from "../../static/images/message.png";

export default function Header() {
  const { user } = useSelector((state) => state.auth);
  const userEmail = localStorage.getItem("userEmail");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [chatScreen, setChatScreen] = useState();
  const location = useLocation();

  useEffect(() => {
    if (window.location.href.includes("chat")) {
      setChatScreen(true);
    } else {
      setChatScreen(false);
    }
  }, [location]);

  return (
    <div className={styles.headerContainer}>
      <Link to="/">
        <img src={logo} className={styles.logoImage} alt="Jeevitam Admin" />
      </Link>
      <div className={styles.dashBoardText}> DASHBOARD</div>
      <div className={styles.userDetails}>
        <p className={styles.userEmail}>
          Hello , {userEmail || (user ? user.email : "Guest")}
        </p>
      </div>
      <CustomButton
        className={chatScreen ? styles.chatActive : styles.logout}
        onClick={() => navigate("/chat")}
        icon={<img src={chatImg} alt="chat-img" height="45" />}
        title="Chat"
      />
      <CustomButton
        className={styles.logout}
        onClick={() => {
          dispatch(logout());
          navigate("/login");
        }}
        icon={<img src={logoutImg} alt="logout-img" height="25" />}
        title="Logout"
      />
    </div>
  );
}
