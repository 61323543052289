/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getBooklist } from "../../../redux/bookReducer/bookSlice";
import { BookSidebar } from "./BookSideBar";
import { CreateNewBook } from "./CreateNewBook";
import { ParticularBook } from "./ParticularBook";
import styles from "../Podcast/podcastContainer.module.scss";

export const BookContainer = React.memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const bookState = useSelector((state) => state.books);
  const { bookList = [] } = bookState || {};
  const [newBookScreen, setNewBookScreen] = useState(true);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const bookId = id;
  const dispatch = useDispatch();
  const [sidebarData, setSideBarData] = useState([]);
  const [selectedBook, setSelectedBook] = useState();

  useEffect(() => {
    if (window.location.href.includes("create-book")) {
      setNewBookScreen(true);
    } else {
      setNewBookScreen(false);
    }
    dataToPassToSidebar();
  }, [location, sidebarData?.length]);

  const onClickBook = (data) => {
    navigate(`/books/${data.bookId.toString()}`);
  };

  const dataToPassToSidebar = () => {
    if (!bookList.length) {
      initialMapSetup();
    } else {
      setInnerView(bookList);
    }
  };

  const setInnerView = (data) => {
    const allData = [...data];
    const filteredData = allData?.filter((pod) => pod?.bookId === bookId);
    setSelectedBook(filteredData?.length > 0 ? filteredData[0] : []);
    setSideBarData(bookList);
  };

  const initialMapSetup = async () => {
    try {
      setLoading(true);
      const response = await dispatch(getBooklist());
      setSideBarData(response.payload.bookList);
      setInnerView(response.payload.bookList);
    } catch {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.podcastContainer}>
      {loading ? (
        <>loading</>
      ) : (
        <>
          <div className={styles.podcastSideBar}>
            <BookSidebar
              onClick={onClickBook}
              bookData={sidebarData}
              bookId={bookId}
            />
          </div>
          <div className={styles.podcastContent}>
            {newBookScreen ? (
              <CreateNewBook />
            ) : (
              <ParticularBook
                key={selectedBook.bookId}
                selectedBook={selectedBook}
                setSelectedBook={setSelectedBook}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
});

export default BookContainer;
