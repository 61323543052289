import React, { useEffect, useState } from "react";
import PodcastsIcon from "@mui/icons-material/Podcasts";
import { Button, Grid, TextField } from "@mui/material";
import styles from "./podcastContainer.module.scss";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DatePicker from "react-date-picker";
import CustomButton from "../../reusable/Button/Button";
import ParticularPodcastDetails from "./ParticularPodcastDetails";
import EditOutlinedIcon from "@mui/icons-material/EditOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import {
  formatDatetoTzFormat,
  formatDateToyyyymmddFormat,
  isEmtpty,
} from "../../../utils/helper";
import { ConfirmationModal } from "../../Modals/ConfirmationModal";
import { axiosInstance } from "../../../redux/axiosBase/axiosInstance";
import { updatePodcastList } from "../../../redux/podcastReducer/podcastSlice";
import { useDispatch, useSelector } from "react-redux";
import { CustomAlert } from "../../reusable/Alerts/Alert";

export const ParticularPodcast = React.memo(
  ({ selectedPodcast, setSelectedPodcast }) => {
    const [currentPodcast, setCurrentPodcast] = useState();
    const [edit, setEdit] = useState(true);
    const [openModal, setOpenModal] = useState(null);
    const [openDeleteModal, setOpenDeleteModal] = useState(null);
    const dispatch = useDispatch();
    const [alertMessage, setAlertMessage] = useState(false);
    const podcastState = useSelector((state) => state.podcasts);
    const { podcastList = [], totalSize = 0, nextOffset = 0 } = podcastState;

    useEffect(() => {
      setCurrentPodcast(selectedPodcast);
    }, [selectedPodcast]);

    const updateSingle = (key, value) => {
      let newdata = { ...currentPodcast };
      newdata[key] = value;
      setCurrentPodcast(newdata);
    };

    const editAndSave = (val) => {
      setAlertMessage(false);
      setEdit(!val);
      if (val === true) {
        return;
      } else {
        setOpenModal(true);
        setSelectedPodcast(currentPodcast);
        // to-do save to backend also
      }
    };

    const editPodcast = async () => {
      if (
        currentPodcast?.audioUrl === "" ||
        isEmtpty(currentPodcast?.title) ||
        isEmtpty(currentPodcast?.episodeId) ||
        isEmtpty(currentPodcast?.description)
      ) {
        alert("Please Fill the mandatory fields");
        return;
      }

      let newPodcast;

      newPodcast = {
        podcastId: currentPodcast?.podcastId,
        title: currentPodcast?.title,
        description: currentPodcast?.description,
        content: currentPodcast?.description,
        yyyymmdd: currentPodcast?.yyyymmdd,
        episodeId: currentPodcast?.episodeId,
        episodeText: currentPodcast?.title,
        timeText: "",
        audioUrl: currentPodcast?.audioUrl,
      };
      const response = await axiosInstance.post(
        "/api/podcast/update",
        newPodcast
      );
      setAlertMessage(response?.data?.success);

      const editedList = podcastList?.map((ins) => {
        if (currentPodcast?.podcastId === ins?.podcastId) {
          ins = response?.data?.data;
        }
        return ins;
      });

      dispatch(
        updatePodcastList({
          podcastlist: editedList,
          totalSize: totalSize,
          nextOffset: nextOffset,
        })
      );
      setOpenModal(false);
    };

    const deletePodcast = async () => {
      const response = await axiosInstance.post(
        `api/podcast/remove?podcastId=${currentPodcast?.podcastId}`
      );
      const editedList = podcastList?.filter((ins) => {
        return ins?.podcastId !== currentPodcast?.podcastId;
      });
      dispatch(
        updatePodcastList({
          podcastlist: editedList,
          totalSize: totalSize,
          nextOffset: nextOffset,
        })
      );
      setAlertMessage(response?.data?.success);
      setOpenDeleteModal(false);
    };

    useEffect(() => {}, []);
    return (
      <div>
        {alertMessage && (
          <CustomAlert
            message={alertMessage ? "Success" : " Something Went Wrong"}
            success={alertMessage}
          />
        )}
        <div>
          <div className={styles.podcastEditRow}>
            <h1 className={styles.createHeaderext}>
              <span>
                <PodcastsIcon fontSize="large" />
              </span>{" "}
              Podcast
            </h1>
            <div className={styles.podcastEditBtns}>
              <CustomButton
                className={styles.editBtn}
                onClick={() => editAndSave(edit)}
                text={
                  <>
                    <EditOutlinedIcon fontSize="small" />
                    <span>{edit ? "Edit" : "Save"}</span>
                  </>
                }
                title={edit ? "Edit" : "Save"}
              />
              <CustomButton
                className={styles.podcastBtn}
                onClick={() => {
                  setAlertMessage(false);
                  setOpenDeleteModal(true);
                }}
                text={
                  <>
                    <DeleteOutlinedIcon fontSize="small" />
                    <span>Delete</span>
                  </>
                }
                title="Delete"
              />
            </div>
          </div>
        </div>
        <div className={styles.titleBox}></div>
        {edit ? (
          <>
            <ParticularPodcastDetails currentPodcast={currentPodcast} />
          </>
        ) : (
          <>
            <Grid container spacing={2} key="title-row">
              <Grid item xs={6} key="file-name">
                <div className={styles.headersText}> File Name</div>
                <div className={styles.contentText}>
                  {" "}
                  <Button
                    variant="contained"
                    component="label"
                    className={styles.audioButton}
                  >
                    <CloudUploadIcon style={{ marginRight: "7px" }} /> Upload
                    Audio File
                    <input type="file" hidden />
                  </Button>
                </div>
              </Grid>
              <Grid item md={3} lg={3} xl={2} key="episode-number">
                <div className={styles.headersText}> Episode Number</div>
                <div>
                  {" "}
                  <TextField
                    id="outlined-basic"
                    label="Episode Number"
                    variant="outlined"
                    value={currentPodcast?.episodeId}
                    onChange={(event) =>
                      updateSingle("episodeId", event.target.value)
                    }
                  />
                </div>
              </Grid>
              <Grid item md={3} lg={3} xl={4} key="date-key-podcast">
                <div className={styles.headersText}> Date</div>
                <div>
                  <DatePicker
                    onChange={(date) => {
                      updateSingle(
                        "yyyymmdd",
                        formatDateToyyyymmddFormat(date)
                      );
                    }}
                    value={formatDatetoTzFormat(currentPodcast?.yyyymmdd)}
                  />
                </div>
              </Grid>
            </Grid>

            <div className={styles.titleBox}>
              <div className={styles.headersText}>Title</div>
              <TextField
                id="outlined-basic"
                label="Title"
                variant="outlined"
                className={styles.titleBox}
                value={currentPodcast?.title}
                onChange={(event) => updateSingle("title", event.target.value)}
              />
            </div>

            <div className={styles.titleBox}>
              <div className={styles.headersText}>Transcription</div>
              <TextField
                id="outlined-basic"
                label="Transcription"
                variant="outlined"
                className={styles.titleBox}
                multiline={true}
                rows={15}
                value={currentPodcast?.description}
                onChange={(event) =>
                  updateSingle("description", event.target.value)
                }
              />
            </div>
          </>
        )}
        {openModal && (
          <ConfirmationModal
            id="open-podcasts-edit"
            open={openModal}
            setOpen={setOpenModal}
            confirmationText={"edit"}
            onConfirmation={editPodcast}
            onCancel={() => setOpenModal(false)}
          />
        )}
        {openDeleteModal && (
          <ConfirmationModal
            id="open-podcasts-delete"
            open={openDeleteModal}
            setOpen={setOpenDeleteModal}
            confirmationText={"delete"}
            onConfirmation={deletePodcast}
            onCancel={() => setOpenDeleteModal(false)}
          />
        )}
      </div>
    );
  }
);
