import { Favorite, PodcastsSharp, ImportContacts } from "@mui/icons-material";

export const podcastData = (data) => {
  return [
    {
      heading: "Most Popular Podcast",
      icon: <PodcastsSharp />,
      title: data[0]?.title || "Podcast dummy (25 Dec 2022)",
      response: data[0]?.count || "0 views",
    },
    {
      heading: "Most liked Podcast",
      icon: <Favorite />,
      title: data[1]?.title || "Podcast 77 (25 Dec 2022)",
      response: data[1]?.count || "7500 likes",
    },
    {
      heading: "Most Read Article",
      icon: <ImportContacts />,
      title: data[2]?.title || "Article title (Jan 2023)",
      response: data[2]?.count || "7500  Readers",
    },
    {
      heading: "Most Liked Article",
      icon: <Favorite />,
      title: data[3]?.title || "Article title (Jan 2023)",
      response: data[3]?.count || "7500 likes",
    },
  ];
};
