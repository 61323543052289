import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import bookService from "./bookService";

export const getBooklist = createAsyncThunk(
  "bookList/getBooklist",
  async (options, thunkAPI) => {
    try {
      let listings = await bookService.getBooklist(options);
      return listings;
    } catch (error) {
      const message = error.response.data._embedded.errors[0].message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  bookList: [],
  isLoading: false,
  isError: false,
};

const bookList = createSlice({
  name: "bookList",
  initialState,
  reducers: {
    reset: (state) => {
      state.bookList = [];
      state.isError = false;
      state.error = "";
    },
    resetError: (state) => {
      state.isError = false;
      state.error = "";
    },
    updateBookList: (state, action) => {
      state.bookList = action.payload.bookList;
      state.totalSize = action.payload?.totalSize;
      state.nextOffset = action.payload?.nextOffset;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBooklist.pending, (state) => {
        state.isLoading = true;
        state.bookList = [];
        state.isError = false;
        state.error = "";
      })
      .addCase(getBooklist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.bookList = action.payload.booklist;
      })
      .addCase(getBooklist.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { reset, resetError, updateBookList } = bookList.actions;

export default bookList.reducer;
