import React from "react";
import styles from "./Button.module.scss";

const CustomButton = ({
  title,
  text,
  icon,
  type = "button",
  theme,
  disabled,
  color,
  bgColor,
  onClick,
  className,
}) => {
  return (
    <button
      title={title}
      style={{ color: color, backgroundColor: bgColor }}
      type={type}
      disabled={disabled}
      className={`${styles.btn} ${styles[theme]} ${className} ${
        !text ? styles.noText : ""
      } ${!icon ? styles.noIcon : ""}`}
      onClick={onClick}
    >
      {icon} {text ? <span>{text}</span> : ""}
    </button>
  );
};

export default CustomButton;
