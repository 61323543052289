import { axiosInstance } from "../axiosBase/axiosInstance";

const podUrl = "/api/podcast/recent?limit=10&offset=0";

const getPodcastlist = async (options) => {
  const list = await axiosInstance.get(podUrl, { params: options });

  console.log(list.data.data.collection, "list.data.data.collection");
  return {
    podcastlist: [...list?.data?.data?.collection],
    totalSize: list?.data?.data?.totalSize,
    nextOffset: list?.data?.data?.nextOffset,
  };
};

const podcastService = {
  getPodcastlist,
};

export default podcastService;
