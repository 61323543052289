import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./auth/authSlice";
import bookSlice from "./bookReducer/bookSlice";
import chatSlice from "./chatReducer/chatSlice";
import eventSlice from "./eventReducer/eventSlice";
import magazineSlice from "./magazineReducer/magazineSlice";
import podcastSlice from "./podcastReducer/podcastSlice";

export const store = configureStore({
  reducer: {
    auth: authSlice,
    podcasts: podcastSlice,
    events: eventSlice,
    books: bookSlice,
    magazines: magazineSlice,
    chats: chatSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});
