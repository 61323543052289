import React, { useEffect, useRef, useState } from "react";
import axios from "axios";

import { Grid } from "@mui/material";
import styles from "./podcastContainer.module.scss";
// import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseCircleOutlineIcon from '@mui/icons-material/PauseCircleOutline';
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
// import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { convertToReadableDate } from "../../../utils/helper";

let audio;

export const ParticularPodcastDetails = React.memo(({ currentPodcast }) => {
  const firstUpdate = useRef(true);
  const [isPlaying, setIsPlaying] = useState(false);

  const pausePodcast = () => {
    if (audio) {
      audio.pause();
      if (audio.src !== currentPodcast?.audioUrl) {
        // THis will make the audio play from start on the next play
        audio.currentTime = 0;
      }
    }
  }

  const playPodcast = () => {
    if (!audio || audio.src !== currentPodcast.audioUrl) {
      pausePodcast();
      audio = new Audio(currentPodcast?.audioUrl);
      audio.onended = () => {
        setIsPlaying(false);
      }
    }
    audio.play();
  }

  const downloadPodcast = async () => {
    try {
      const response = await axios({
        url: currentPodcast.audioUrl,
        method: 'GET',
        responseType: 'blob',
      });
      const url = URL.createObjectURL(response.data);
      const a = document.createElement('a');
      a.href = url;
      a.download = 'audio.mp3';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    // To overcome the first update when the component mounts for the first time
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    if (isPlaying) {
      playPodcast();
    } else {
      pausePodcast();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying]);

  useEffect(() => {
    if(!currentPodcast) {
      return;
    }
    // To check if the audio playing is the same audio
    if (audio && audio.src === currentPodcast.audioUrl && !audio.paused) {
      setIsPlaying(true);
    } else {
      setIsPlaying(false);
    }
  }, [currentPodcast]);

  return (
    <>
      <Grid container spacing={2} key="title-row">
        <Grid item xs={6} key="file-name">
          <div className={styles.headersText}> File Name</div>
          <div className={styles.contentText}>
            {"file name "}
            {
              isPlaying
                ? <PauseCircleOutlineIcon onClick={() => setIsPlaying(false)} />
                : <PlayArrowIcon onClick={() => setIsPlaying(true)} />
            }
            {/* <CloudUploadOutlinedIcon /> */}
            {" "}
            <FileDownloadOutlinedIcon
              onClick={downloadPodcast}
            />
            {/* <DeleteOutlinedIcon /> */}
            {" "}
          </div>
        </Grid>
        <Grid item xs={2} key="episode-number">
          <div className={styles.headersText}> Episode Number</div>
          <div> {currentPodcast?.episodeId}</div>
        </Grid>
        <Grid item xs={4} key="date-key-podcastD">
          <div className={styles.headersText}> Date</div>
          <div>{convertToReadableDate(currentPodcast?.yyyymmdd)}</div>
        </Grid>
      </Grid>

      <div className={styles.titleBox}>
        <div className={styles.headersText}>Title</div>
        <div>{currentPodcast?.title}</div>
      </div>

      <div className={styles.titleBox}>
        <div className={styles.headersText}>Transcription</div>
        <div>{currentPodcast?.description}</div>
      </div>
    </>
  );
});

export default ParticularPodcastDetails;
