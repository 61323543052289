import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";

import React from "react";

export const ConfirmationModal = React.memo(
  ({ open, setOpen, confirmationText = "edit", onConfirmation, onCancel }) => {
    const handleClose = () => {
      setOpen(false);
    };
    return (
      <div>
        {" "}
        <Dialog
          fullWidth={false}
          maxWidth={"lg"}
          open={open}
          onClose={handleClose}
        >
          <DialogTitle>Confirm Before you proceed</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {`Are you sure to ${confirmationText} this?`}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={onConfirmation}>Yes</Button>
            <Button onClick={onCancel}>No</Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
);
