/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { axiosInstance } from "../../../redux/axiosBase/axiosInstance";
import {
  getchatList,
  updateUserDetails,
} from "../../../redux/chatReducer/chatSlice";
import styles from "../Podcast/podcastContainer.module.scss";
import { ChatSideBar } from "./ChatSideBar";
import { ParticularUserChat } from "./ParticularUserChat";

export const ChatContainer = React.memo(() => {
  const location = useLocation();
  const navigate = useNavigate();
  const chatState = useSelector((state) => state.chats);
  const { chatList, userDetails } = chatState;
  const [newChatScreen, setnewChatScreen] = useState(true);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const chatId = id;
  const dispatch = useDispatch();
  const [sidebarData, setSideBarData] = useState([""]);
  const [selectedChat, setSelectedChat] = useState();
  const [selectedUser, setSelectedUser] = useState();

  useEffect(() => {
    if (window.location.href.includes("create-chat")) {
      setnewChatScreen(true);
    } else {
      setnewChatScreen(false);
    }
    dataToPassToSidebar(); // please check this because this wont update on the redux state change
  }, [location]);

  const onClickUser = (data) => {
    navigate(`/chat/${data?.uid?.toString()}`);
  };

  const dataToPassToSidebar = () => {
    if (JSON.stringify(chatList) === "{}") {
      initialMapSetup();
    } else {
      setInnerView(chatList, userDetails);
    }
  };

  function mapData(chatData) {
    const storeIds = {};
    const dataset = [];
    for (let index in chatData.data) {
      if (chatData?.data[index]?.fromUid in storeIds) {
        dataset[storeIds[chatData.data[index]?.fromUid]]?.content?.push({
          ...chatData?.data[index],
        });
      } else {
        storeIds[chatData?.data[index]?.fromUid] = index;
        dataset.push({
          fromUid: chatData?.data[index]?.fromUid,
          content: [{ ...chatData.data[index] }],
        });
      }
    }

    return dataset;
  }

  const setInnerView = (data, userData) => {
    const allData = { ...data };
    const filteredData = allData.data?.filter((i) => i.fromUid === chatId);
    const dataset = mapData(allData);
    const uids = dataset.map((i) => {
      return { uid: i?.fromUid };
    });

    let mergedList = [];
    for (let i = 0; i < uids.length; i++) {
      mergedList.push({
        ...uids[i],
        ...userData?.find((itmInner) => itmInner?.uid === uids[i]?.uid),
      });
    }
    setSideBarData(mergedList);
    const user = mergedList?.filter((i) => i?.uid === chatId);
    setSelectedUser(user);

    setSelectedChat(filteredData?.length > 0 ? filteredData : []);
  };

  const initialMapSetup = async () => {
    try {
      setLoading(true);
      const response = await dispatch(getchatList());
      const userResponse = await axiosInstance.get("/api/userdata/list");
      let usdata = userResponse?.data?.data;
      dispatch(updateUserDetails(usdata));
      setInnerView(response.payload.chatList, usdata);
    } catch (err) {
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className={styles.podcastContainer}>
      {loading ? (
        <>loading</>
      ) : (
        <>
          <div className={styles.podcastSideBar}>
            <ChatSideBar
              onClick={onClickUser}
              chatData={sidebarData}
              chatId={chatId}
            />
          </div>
          <div className={styles.podcastContent}>
            {newChatScreen ? (
              <div className={styles.select__user__content}>
                select a user to start messaging
              </div> // if Admin wants to send message first we can create messgae screen for new user chat
            ) : (
              <ParticularUserChat
                chatId={chatId}
                selectedChat={selectedChat}
                setSelectedChat={setSelectedChat}
                selectedUser={selectedUser}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
});

export default ChatContainer;
