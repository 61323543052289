import React, { useState } from "react";
import { Button, Grid, TextField } from "@mui/material";
import styles from "../Podcast/podcastContainer.module.scss";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import CustomButton from "../../reusable/Button/Button";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { useSelector } from "react-redux";
import { isEmtpty } from "../../../utils/helper";
import { axiosInstance } from "../../../redux/axiosBase/axiosInstance";
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { storage } from "../../../firebaseConfig";
import { CustomAlert } from "../../reusable/Alerts/Alert";

export const CreateNewArticle = () => {
  const [newArticleState, setnewArticleState] = useState({});
  const magzineState = useSelector((state) => state.magazines);
  const { magId = "" } = magzineState || {};
  const [alertMessage, setAlertMessage] = useState(false);

  const updateSingle = (key, value) => {
    let newdata = { ...newArticleState };
    newdata[key] = value;
    setnewArticleState(newdata);
  };

  const handleCancel = () => {
    //clears all State
    setnewArticleState({});
  };

  const handleCreateNew = async () => {
    //to - do in backend also
    if (
      magId === "" ||
      isEmtpty(newArticleState?.imageUrl) ||
      isEmtpty(newArticleState?.title) ||
      isEmtpty(newArticleState?.content)
    ) {
      alert("Please Fill the mandatory fields");
      return;
    }

    let newArticle = {
      magazineId: magId,
      imageUrl: newArticleState?.imageUrl,
      title: newArticleState?.title,
      author: "someone",
      content: newArticleState?.content,
      pubDate: new Date().toISOString().split("T")[0],
      readTimeMins: 20,
      readTimeText: "read time",
    };
    const response = await axiosInstance.post(
      "/api/magazine/article/publish",
      newArticle
    );
    setAlertMessage(response?.data?.success);
  };

  const updateArticleImage = (e) => {
    let file = e.target.files[0];
    if (!file) {
      alert("Please choose a file first!");
    }
    if (file) {
      const storageRef = ref(storage, `articles/${file.name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);
      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const percent = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (err) => console.log(err),
        () => {
          // download url
          getDownloadURL(uploadTask?.snapshot?.ref)?.then((url) => {
            updateSingle("imageUrl", url);
          });
        }
      );
    }
  };

  return (
    <div>
      {alertMessage && (
        <CustomAlert
          message={
            alertMessage
              ? "Article Added Successfully"
              : " Something Went Wrong"
          }
          success={alertMessage}
        />
      )}
      <div>
        <h1 className={styles.createHeaderext}>
          <span>
            <LibraryBooksIcon fontSize="large" />
          </span>
          Add New Article
        </h1>
      </div>
      <Grid container spacing={2} key="book-title-row">
        <Grid item xs={6} md={6} lg={6} key="book-name">
          <div className={styles.headersText}> Title </div>
          <div className={styles.contentText}>
            <TextField
              id="outlined-basic"
              label="Title Name"
              variant="outlined"
              value={newArticleState?.title}
              onChange={(e) => updateSingle("title", e.target.value)}
            />
          </div>
        </Grid>

        <Grid item md={6} lg={6} xl={6} key="date-key">
          <div className={styles.headersText}> Article Cover Image</div>
          <div>
            <Button
              variant="contained"
              component="label"
              className={styles.audioButton}
            >
              <CloudUploadIcon style={{ marginRight: "7px" }} /> Article Cover
              Image
              <input
                type="file"
                hidden
                onChange={(e) => updateArticleImage(e)}
                accept="image/png, image/gif, image/jpeg"
              />
            </Button>
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={2} key="content-row">
        <Grid item md={6} lg={6} xl={6} key="content-article">
          <div className={styles.titleBox}>
            <div className={styles.headersText}>Content</div>
            <TextField
              id="outlined-basic"
              label="Content"
              variant="outlined"
              className={styles.titleBox}
              multiline={true}
              rows={15}
              value={newArticleState?.content}
              onChange={(e) => updateSingle("content", e.target.value)}
            />
          </div>
        </Grid>
        <Grid item md={6} lg={6} xl={6}>
          {newArticleState?.imageUrl && (
            <div style={{ paddingTop: "4.5em" }} key="article-img">
              <img
                src={newArticleState?.imageUrl}
                height={400}
                width={600}
                alt="Article-img"
              />
            </div>
          )}
        </Grid>
      </Grid>
      <div className={styles.buttonRow}>
        <CustomButton
          className={styles.btn}
          onClick={() => {
            handleCancel();
          }}
          text="Cancel"
          title="Cancel"
        />
        <CustomButton
          className={styles.podcastBtn}
          onClick={() => {
            handleCreateNew();
          }}
          text="Add Article"
          title="Add Article"
        />
      </div>
    </div>
  );
};
