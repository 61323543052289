import React from "react";
import { Grid } from "@mui/material";
import styles from "../Podcast/podcastContainer.module.scss";

export const ParticularBookDetails = React.memo(({ currentBook }) => {
  return (
    <>
      <Grid container spacing={2} key="book-title-row">
        <Grid item xs={4} md={4} lg={4} key="book-name">
          <div className={styles.headersText}> Book Name</div>
          <div className={styles.contentText}> {currentBook?.title}</div>
        </Grid>
        <Grid item md={4} lg={4} xl={4} key="book-author">
          <div className={styles.headersText}> Author</div>
          <div>{currentBook?.bookAuthor}</div>
        </Grid>
        <Grid item md={3} lg={3} xl={4} key="book-covers-key">
          <div className={styles.headersText}> Book Cover Image</div>
          <div>
            <img
              src={currentBook?.imageUrl}
              alt="bookImage"
              height={200}
              width={400}
            />
          </div>
        </Grid>
      </Grid>

      <div className={styles.titleBox}>
        <div className={styles.headersText}>Description</div>
        {currentBook?.description}
      </div>

      <div className={styles.titleBox}>
        <Grid container spacing={2} key="sale-amazon-row">
          <Grid item xs={3} md={3} lg={3} key="amazon">
            <div className={styles.headersText}> Sale on Amazon</div>
            <div className={styles.contentText}>
              {currentBook?.buyOnAmazon ? "YES" : "NO"}
            </div>
          </Grid>
          <Grid item md={9} lg={9} xl={9} key="amazon-address">
            <div className={styles.headersText}>Amazon Link</div>
            <div>
              <a href={currentBook?.amazonUrl}> {currentBook?.amazonUrl}</a>
            </div>
          </Grid>
        </Grid>
      </div>

      <div className={styles.titleBox}>
        <Grid container spacing={2} key="sale-flipkart-row">
          <Grid item xs={3} md={3} lg={3} key="flipkart">
            <div className={styles.headersText}> Sale on Flipkart</div>
            <div className={styles.contentText}>
              {currentBook?.buyOnFlipkart ? "YES" : "NO"}
            </div>
          </Grid>
          <Grid item md={9} lg={9} xl={9} key="flip-link">
            <div className={styles.headersText}>Flipkart Link</div>
            <div>
              <a href={currentBook?.flipkartUrl}>{currentBook?.flipkartUrl}</a>
            </div>
          </Grid>
        </Grid>
      </div>
    </>
  );
});
