import React from "react";
import { useNavigate } from "react-router-dom";
import CustomButton from "../reusable/Button/Button";
import NotFoundUFO from "../../static/images/NotFound.png";
import styles from "./NotFound.module.scss";

const NotFound = () => {
  const navigate = useNavigate();

  const back = () => {
    navigate("/");
  };

  return (
    <div className={styles.container}>
      <div className={styles.body}>
        <img src={NotFoundUFO} alt="Page no found" />
        <h2>Oops page not found</h2>
        <p>This page doesn't exist or was removed. </p>
        <p>We suggest you to go back.</p>
        <CustomButton
          color="#5CB7C0"
          className={styles.btn}
          text="Go back"
          title="Go back"
          onClick={back}
        />
      </div>
    </div>
  );
};

export default NotFound;
