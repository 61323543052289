import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import Fade from "@mui/material/Fade";
import { useState } from "react";

export const CustomAlert = ({ message = "Success", success = true }) => {
  const [alertVisibility, setAlertVisibility] = useState(true);

  return (
    <Fade
      in={alertVisibility}
      timeout={{ enter: 1000, exit: 1000 }}
      addEndListener={() => {
        setTimeout(() => {
          setAlertVisibility(false);
        }, 5000);
      }}
    >
      <Alert
        severity={success ? "success" : "error"}
        variant="standard"
        className="alert"
      >
        <AlertTitle>{message}</AlertTitle>
        {message}
      </Alert>
    </Fade>
  );
};
