import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import chatService from "./chatService";

export const getchatList = createAsyncThunk(
  "chatList/getChatList",
  async (options, thunkAPI) => {
    try {
      let listings = await chatService.getchatList(options);
      return listings;
    } catch (error) {
      const message = error.response.data._embedded.errors[0].message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  chatList: {},
  isLoading: false,
  isError: false,
};

const chatList = createSlice({
  name: "chatList",
  initialState,
  reducers: {
    reset: (state) => {
      state.chatList = {};
      state.isError = false;
      state.error = "";
    },
    resetError: (state) => {
      state.isError = false;
      state.error = "";
    },
    updateList: (state, action) => {
      state.error = "";
      state.chatList.data = [...state.chatList.data, action.payload.newChat];
    },
    updateUserDetails: (state, action) => {
      state.userDetails = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getchatList.pending, (state) => {
        state.isLoading = true;
        state.chatList = {};
        state.isError = false;
        state.error = "";
      })
      .addCase(getchatList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.chatList = action.payload.chatList;
      })
      .addCase(getchatList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { reset, resetError, updateList, updateUserDetails } =
  chatList.actions;

export default chatList.reducer;
