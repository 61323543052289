import React from "react";
import { getdate, getMonth } from "../../../utils/helper";
import styles from "../Podcast/podcastContainer.module.scss";

export const RenderRowCardEvent = React.memo(
  ({ eventList, index, activeEvent, selectCell, keyForCard, style }) => {
    return (
      <div key={`row-card-${keyForCard}`} className={``} style={style}>
        <div
          className={`${styles.eventCard} ${
            activeEvent ? styles.activepodcast : ""
          }`}
          role="button"
          onClick={() => {
            selectCell(index, eventList[index]);
          }}
        >
          <div className={styles.podcastCircle}>
            <div style={{ paddingTop: "10px" }}>
              {" "}
              {getdate(`${eventList[index]?.yyyymmddhhmm}`)}{" "}
            </div>
            <div> {getMonth(`${eventList[index]?.yyyymmddhhmm}`)}</div>
          </div>

          <div className={styles.podcastDetails}>
            <div
              className={styles.secondaryTitleText}
              title={eventList[index]?.title}
            >
              {eventList[index]?.title}
            </div>
            <div className={styles.titleText}>
              {eventList[index]?.eventType}
            </div>
            <div
              className={styles.descText}
              title={eventList[index]?.description}
            >
              {eventList[index]?.description}
            </div>
          </div>
        </div>
      </div>
    );
  }
);
