import React, { useState, useRef, useEffect } from "react";
import { CellMeasurer, CellMeasurerCache } from "react-virtualized";
import styles from "../Podcast/podcastContainer.module.scss";
import { VirtualizedList } from "../../reusable/VitualisedList/VirtualisedList";
import { Button, TextField } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import { useNavigate } from "react-router-dom";
import { RenderRowCardBook } from "./RenderRowCardBooks";
import { useDispatch, useSelector } from "react-redux";
import { axiosInstance } from "../../../redux/axiosBase/axiosInstance";
import { updateBookList } from "../../../redux/bookReducer/bookSlice";

export const BookSidebar = React.memo(({ onClick, bookId }) => {
  const calculatedHeight = useRef();
  const [searchByName, searchByNameHandler] = useState("");
  const [scrollToRow, scrollToRowHandler] = useState(-1);
  const [height, setHeight] = useState(window.innerHeight - 285);
  const [allData, setAllData] = useState([]);
  const [scrollDisabled, setScrollDisabled] = useState(true);
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 96,
  });

  const bookState = useSelector((state) => state.books);
  const { bookList = [], totalSize = 0, nextOffset = 0 } = bookState || {};
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const resizeListener = () => {
      setHeight(window.innerHeight - 285);
    };
    window.addEventListener("resize", resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    setAllData(bookList);
  }, [bookList]);

  useEffect(() => {
    setScrollToRow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData]);

  const filteredByNameData = (name) => {
    const searchedData = bookList?.filter((ins) =>
      ins?.title?.toLowerCase()?.includes(name?.toLowerCase())
    );
    setAllData(searchedData);
  };

  const setScrollToRow = () => {
    const currentIndex = allData.findIndex((data) => data?.bookId === bookId);
    if (currentIndex !== scrollToRow && currentIndex !== -1) {
      scrollToRowHandler(currentIndex);
    }
  };

  const selectCell = (index, data) => {
    scrollToRowHandler(index);
    onClick(data);
  };

  const renderRow = ({ index, style, scrollToRow, parent }) => {
    const isActive = index === scrollToRow;

    return (
      <div className={""} key={`podcast-list-${index}`}>
        <CellMeasurer
          key={`cell-measurer-report-card-${index}`}
          cache={cache}
          columnIndex={0}
          parent={parent}
          rowIndex={index}
        >
          {() => (
            <RenderRowCardBook
              keyForCard={`inner-podcast-row-card-${index}`}
              podcastList={allData}
              index={index}
              style={style}
              activepodcast={isActive ? `${styles.activeRow}` : ""}
              selectCell={(index, data) => {
                selectCell(index, data);
              }}
            />
          )}
        </CellMeasurer>
      </div>
    );
  };

  const handleKeyDown = ({ keyCode }) => {
    const data = allData[scrollToRow];
    if (keyCode === 13 && data) {
      onClick(data);
    }
  };

  const createNewBook = () => {
    navigate("/books/create-book");
  };

  const loadMoreBooks = async () => {
    const response = await axiosInstance.get(
      `/api/book/recent?limit=10&offset=${nextOffset}`
    );
    dispatch(
      updateBookList({
        bookList: [bookList, ...response?.data?.data?.collection],
        totalSize: response?.data?.data?.totalSize,
        nextOffset: response?.data?.data?.nextOffset,
      })
    );
  };

  return (
    <div>
      <div className={styles.createNewBox} onClick={() => createNewBook()}>
        <AddCircleOutlineIcon fontSize={"large"} color="inherit" />
        <div className={styles.createText}>Add New Book</div>
      </div>
      <div className={styles.upDownPadding}>
        <TextField
          id="search-by-book-name"
          label="Search Book"
          variant="outlined"
          value={searchByName}
          onChange={(e) => {
            searchByNameHandler(e.target.value);
            filteredByNameData(e.target.value);
          }}
          className={styles.widthBox}
        />
      </div>
      <div
        className={styles.heightfilter}
        ref={calculatedHeight}
        onKeyDown={handleKeyDown}
        onMouseOver={() => setScrollDisabled(false)}
        role="button"
      >
        {allData.length === 0 && (
          <div className={styles.noData}>
            <span>{"No Books Available"}</span>
          </div>
        )}
        {allData.length > 0 && (
          <>
            <VirtualizedList
              cache={cache}
              cardWidth={300}
              height={height}
              rowCount={allData.length}
              rowRenderer={(params) =>
                renderRow({ scrollToRow: scrollToRow, ...params })
              }
              scrollDisabled={scrollDisabled}
              scrollToRowParent={scrollToRow}
            />
          </>
        )}
      </div>
      {/* pagination goes here  */}
      {totalSize > 9 && (
        <div className={styles.loadMoreBtn}>
          <Button onClick={() => loadMoreBooks()}>Load More</Button>
        </div>
      )}
    </div>
  );
});
