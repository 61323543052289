import { axiosInstance } from "../axiosBase/axiosInstance";

const bookUrl = "/api/book/recent?limit=10&offset=0";

const getBooklist = async (options) => {
  const list = await axiosInstance.get(bookUrl, { params: options });
  return {
    booklist: [...list.data.data.collection],
    totalSize: list?.data?.data?.totalSize,
    nextOffset: list?.data?.data?.nextOffset,
  };
};

const bookService = {
  getBooklist,
};

export default bookService;
