import React from 'react';

import moment from 'moment';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DateTimePicker as MuiDateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

export const DateTimePicker = ({ label, value, setValue }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterMoment}>
      <MuiDateTimePicker
        label={label || null}
        value={moment(value)}
        onChange={setValue}
      />
    </LocalizationProvider>
  );
}
