import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import podcastService from "./podcastService";

export const getPodcastlist = createAsyncThunk(
  "podcastList/getPodcastlist",
  async (options, thunkAPI) => {
    try {
      let listings = await podcastService.getPodcastlist(options);
      return listings;
    } catch (error) {
      const message = error.response.data._embedded.errors[0].message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  podcastList: [],
  totalSize: 0,
  isLoading: false,
  isError: false,
};

const podcastList = createSlice({
  name: "podcastList",
  initialState,
  reducers: {
    reset: (state) => {
      state.podcastList = [];
      state.isError = false;
      state.error = "";
      state.totalSize = 0;
      state.nextOffset = 0;
    },
    resetError: (state) => {
      state.isError = false;
      state.error = "";
    },
    updatePodcastList: (state, action) => {
      state.podcastList = [...action.payload?.podcastlist];
      state.totalSize = action.payload?.totalSize;
      state.nextOffset = action.payload?.nextOffset;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getPodcastlist.pending, (state) => {
        state.isLoading = true;
        state.podcastList = [];
        state.isError = false;
        state.error = "";
      })
      .addCase(getPodcastlist.fulfilled, (state, action) => {
        console.log("user", action);
        state.isLoading = false;
        state.podcastList = action.payload?.podcastlist;
        state.totalSize = action.payload?.totalSize;
        state.nextOffset = action.payload?.nextOffset;
      })
      .addCase(getPodcastlist.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { reset, resetError, updatePodcastList } = podcastList.actions;

export default podcastList.reducer;
