import React, { useState, useRef, useEffect } from "react";
import { CellMeasurer, CellMeasurerCache } from "react-virtualized";
import styles from "../Podcast/podcastContainer.module.scss";
import { VirtualizedList } from "../../reusable/VitualisedList/VirtualisedList";
import { TextField } from "@mui/material";
import chatImg from "../../../static/images/message.png";
import { useNavigate } from "react-router-dom";
import { RenderRowCardUser } from "./RenderRowCardUser";

export const ChatSideBar = React.memo(({ onClick, chatData, chatId }) => {
  const calculatedHeight = useRef();
  const [searchByName, searchByNameHandler] = useState("");
  const [scrollToRow, scrollToRowHandler] = useState(-1);
  const [height, setHeight] = useState(window.innerHeight - 255);
  const [allData, setAllData] = useState([]);
  const [scrollDisabled, setScrollDisabled] = useState(true);
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 96,
  });

  const navigate = useNavigate();

  useEffect(() => {
    const resizeListener = () => {
      setHeight(window.innerHeight - 255);
    };
    window.addEventListener("resize", resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    setAllData(chatData);
  }, [chatData]);

  useEffect(() => {
    setScrollToRow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData]);

  const filteredByNameData = (name) => {
    // return null; // filter cannot be done rite now due to unavailibiluty if data coming in the api
    const searchedData = chatData?.filter((ins) =>
      ins?.name?.toLowerCase()?.includes(name?.toLowerCase())
    );
    setAllData(searchedData);
  };

  const setScrollToRow = () => {
    const currentIndex = allData.findIndex((data) => data?.fromUid === chatId);
    if (currentIndex !== scrollToRow && currentIndex !== -1) {
      scrollToRowHandler(currentIndex);
    }
  };

  const selectCell = (index, data) => {
    scrollToRowHandler(index);
    onClick(data);
  };

  const renderRow = ({ index, style, scrollToRow, parent }) => {
    const isActive = index === scrollToRow;
    return (
      <div className={""} key={`podcast-list-${index}`}>
        <CellMeasurer
          key={`cell-measurer-report-card-${index}`}
          cache={cache}
          columnIndex={0}
          parent={parent}
          rowIndex={index}
        >
          {() => (
            <>
              <RenderRowCardUser
                keyForCard={`inner-podcast-row-card-${index}`}
                podcastList={allData}
                index={index}
                style={style}
                activeUser={isActive}
                selectCell={(index, data) => {
                  selectCell(index, data);
                }}
              />
            </>
          )}
        </CellMeasurer>
      </div>
    );
  };

  const handleKeyDown = ({ keyCode }) => {
    const data = allData[scrollToRow];
    if (keyCode === 13 && data) {
      onClick(data);
    }
  };

  const createNewChat = () => {
    navigate("/chat/create-chat");
  };

  return (
    <div>
      <div className={styles.messageText} onClick={() => createNewChat()}>
        <img src={chatImg} alt="chat-img" height="45" />{" "}
        <h1 style={{ paddingTop: "5px" }}>Messages</h1>
      </div>
      <div className={styles.upDownPadding}>
        <TextField
          id="search-by-user-name"
          label="Search User"
          variant="outlined"
          value={searchByName}
          onChange={(e) => {
            searchByNameHandler(e.target.value);
            filteredByNameData(e.target.value);
          }}
          className={styles.widthBox}
        />
      </div>
      <div
        className={styles.heightfilter}
        ref={calculatedHeight}
        onKeyDown={handleKeyDown}
        onMouseOver={() => setScrollDisabled(false)}
        role="button"
      >
        {allData?.length === 0 && (
          <div className={styles.noData}>No Chats Available</div>
        )}
        {allData?.length > 0 && (
          <>
            <VirtualizedList
              cache={cache}
              cardWidth={400}
              height={height}
              rowCount={allData?.length}
              rowRenderer={(params) =>
                renderRow({ scrollToRow: scrollToRow, ...params })
              }
              scrollDisabled={scrollDisabled}
              scrollToRowParent={scrollToRow}
            />
          </>
        )}
      </div>
    </div>
  );
});
