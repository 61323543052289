import { Provider } from "react-redux";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import "./App.css";
import DashBoard from "./components/DashBoard/DashBoard";
import { ErrorBoundary } from "./components/ErrorBoundary/ErrorBoundary";
import Layout from "./components/Layout/Layout";
import React from "react";
import Login from "./components/Login/Login";
import NotFound from "./components/NotFound/NotFound";
import BookContainer from "./components/Pages/Book/BookContainer";
import EventContainer from "./components/Pages/Event/EventContainer";
import MagazineContainer from "./components/Pages/Magazine/MagazineContainer";
import PodcastContainer from "./components/Pages/Podcast/PodcastContainer";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import { store } from "./redux/store";
import { ChatContainer } from "./components/Pages/Chat/Chat";
import { PrivacyPolicy } from "./components/PrivacyPolicy/PrivacyPolicy";

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <BrowserRouter>
          <ErrorBoundary>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/privacy-policy" element={<PrivacyPolicy />} />

              <Route
                path=""
                element={
                  <ProtectedRoute>
                    <Layout />
                  </ProtectedRoute>
                }
              >
                <Route path="/" element={<DashBoard />} />

                <Route path="/podcast">
                  <Route
                    path="/podcast"
                    element={
                      <Navigate to="/podcast/create-podcast" replace={true} />
                    }
                  />
                  <Route
                    index
                    path="/podcast/create-podcast"
                    element={<PodcastContainer />}
                  />
                  <Route path="/podcast/:id" element={<PodcastContainer />} />
                </Route>

                <Route path="/magazine">
                  <Route
                    path="/magazine"
                    element={
                      <Navigate to="/magazine/create-article" replace={true} />
                    }
                  />
                  <Route
                    index
                    path="/magazine/create-article"
                    element={<MagazineContainer />}
                  />
                  <Route path="/magazine/:id" element={<MagazineContainer />} />
                </Route>

                <Route path="/books">
                  <Route
                    path="/books"
                    element={
                      <Navigate to="/books/create-book" replace={true} />
                    }
                  />
                  <Route
                    index
                    path="/books/create-book"
                    element={<BookContainer />}
                  />
                  <Route path="/books/:id" element={<BookContainer />} />
                </Route>

                <Route path="/event">
                  <Route
                    path="/event"
                    element={
                      <Navigate to="/event/create-event" replace={true} />
                    }
                  />
                  <Route
                    index
                    path="/event/create-event"
                    element={<EventContainer />}
                  />
                  <Route path="/event/:id" element={<EventContainer />} />
                </Route>

                <Route path="/chat">
                  <Route
                    path="/chat"
                    element={<Navigate to="/chat/create-chat" replace={true} />}
                  />
                  <Route
                    index
                    path="/chat/create-chat"
                    element={<ChatContainer />}
                  />
                  <Route path="/chat/:id" element={<ChatContainer />} />
                </Route>
              </Route>
              <Route path="*" element={<NotFound />} />
            </Routes>
          </ErrorBoundary>
        </BrowserRouter>
      </Provider>
    </div>
  );
}

export default App;
