import React, { useEffect, useRef, useState } from "react";
import {
  AutoSizer,
  CellMeasurer,
  CellMeasurerCache,
  WindowScroller,
} from "react-virtualized";
import moment from "moment";

import { axiosInstance } from "../../../redux/axiosBase/axiosInstance";
import {
  convertToReadableDate,
  parseDataTimeToObjectFormat,
} from "../../../utils/helper";
import { VirtualizedList } from "../../reusable/VitualisedList/VirtualisedList";
import styles from "./chat.module.scss";
import { useDispatch } from "react-redux";
import { updateList } from "../../../redux/chatReducer/chatSlice";

export const ParticularUserChat = React.memo(
  ({ selectedChat, chatId, selectedUser }) => {
    return (
      <div>
        <header className={styles.header}>
          <h1>{selectedUser[0]?.name}</h1>
        </header>
        <section className={styles.section}>
          <ChatRoom
            messageList={selectedChat}
            chatId={chatId}
            selectedUser={selectedUser}
          />
        </section>
      </div>
    );
  }
);

const ChatRoom = React.memo(({ messageList, chatId, selectedUser }) => {
  const dispatch = useDispatch();
  const lastMessageRef = useRef();
  const [messages, setMessgaes] = useState();
  const [formValue, setFormValue] = useState("");
  const [messageListWithoutGrouping, setMessageListWithoutGrouping] = useState(
    []
  );
  const [scrollToRow, scrollToRowHandler] = useState(-1);
  const [height, setHeight] = useState(window.innerHeight - 300);
  const cache = new CellMeasurerCache({
    fixedWidth: true,
    defaultHeight: 96,
  });

  useEffect(() => {
    const resizeListener = () => {
      setHeight(window.innerHeight - 300);
    };
    window.addEventListener("resize", resizeListener);
    resizeListener();
    return () => {
      window.removeEventListener("resize", resizeListener);
    };
  }, []);

  useEffect(() => {
    setMessgaes(generateItems(messageList));
    setMessageListWithoutGrouping(messageList);
  }, [messageList]);

  useEffect(() => {
    if (lastMessageRef) {
      lastMessageRef?.current?.scrollIntoView({
        behavior: "smooth",
      });
    }
    scrollToRowHandler(messages?.length - 1);
  }, [messages]);

  const renderRow = ({ index, style, scrollToRow, parent }) => {
    return (
      <div className={""} key={`chat-list-${index}`}>
        <CellMeasurer
          key={`cell-measurer-user-msg-${index}`}
          cache={cache}
          columnIndex={0}
          parent={parent}
          rowIndex={index}
        >
          {() => (
            <RenderItem
              data={messages}
              style={style}
              index={index}
              selectedUser={selectedUser}
            />
          )}
        </CellMeasurer>
      </div>
    );
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    const currentDate = new Date().toISOString().split(".");
    currentDate.pop();
    const newChat = {
      content: formValue,
      sentTime: currentDate.join(),
      fromUid: chatId,
      fromAuthor: true,
    };

    const newlist = [
      ...messageListWithoutGrouping,
      {
        content: formValue,
        sentTime: parseDataTimeToObjectFormat(currentDate.join()),
        fromUid: chatId,
        fromAuthor: true,
      },
    ];

    axiosInstance.post("/api/contact/addmessage", newChat).then((res) => {
      //  dispatch action to store the new chat data into the redux
      dispatch(updateList({ newChat }));
    });

    setMessageListWithoutGrouping(newlist);
    const updatedList = generateItems(newlist);
    setMessgaes(updatedList);
    setFormValue("");
  };

  return (
    <>
      <main className={styles.main}>
        <div role="button" className={styles.messagesArea}>
          {messageListWithoutGrouping &&
            messageListWithoutGrouping?.length > 0 && (
              <>
                <WindowScroller>
                  {({ onChildScroll }) => (
                    <AutoSizer disableHeight>
                      {({ width }) => {
                        return (
                          <VirtualizedList
                            key={"particular-chat-screen"}
                            cache={cache}
                            cardWidth={width}
                            height={height}
                            rowCount={messages?.length}
                            rowRenderer={(params) =>
                              renderRow({ scrollToRow: scrollToRow, ...params })
                            }
                            onScroll={onChildScroll}
                            scrollToRowParent={scrollToRow}
                          />
                        );
                      }}
                    </AutoSizer>
                  )}
                </WindowScroller>
                {/* 
                // if the above code creates any problem in scrolling or etc , use  the below one
                {messages.map((msg, index) => (
                <RenderItem data={messages} index={index} />
              ))} */}
              </>
            )}
        </div>
        <div ref={lastMessageRef} className={styles.emptyDiv}></div>
      </main>
      <form onSubmit={sendMessage} className={styles.form}>
        <input
          className={styles.input}
          value={formValue}
          onChange={(e) => setFormValue(e.target.value)}
          placeholder="say something nice"
        />
        <button className={styles.button} type="submit">
          send message
        </button>
      </form>
    </>
  );
});

const ChatMessage = React.memo((props) => {
  // not using diffInMinutes as of now , we can use it when a user send a message in same time interval many times so we need to check if the same user has sent more than 2 messages when timeinterval is lessthan 30 mins we can hide the displayed time
  // const { diffInMinutes } = props;
  const userImage = props?.selectedUser[0]?.imageUrl;
  const { fromAuthor, browserTime, content } = props.message;
  const messageClass = fromAuthor ? styles.sent : styles.received;
  const timeClass = fromAuthor ? styles.timeLeft : styles.timeRight;
  const authImage =
    "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSx4CtTjcL4KC9GoJ_BTx5R1UmktoHM2fSYIg&usqp=CAU";

  return (
    <>
      <div className={`${styles.message} ${messageClass}`}>
        <img
          className={styles.img}
          src={fromAuthor ? authImage : userImage}
          alt="user"
        />
        <p className={styles.p}>{content}</p>
      </div>
      {
        <p className={timeClass}>
          {" "}
          {browserTime}
        </p>
      }
    </>
  );
});

const getTime = (sentTime) => {
  const addZero = (val) => {
    if (val < 10) {
      return `0${val}`
    }
    return `${val}`
  }

  if (sentTime && sentTime.date && sentTime.time) {
    const {
      date: { year, month, day },
      time: { hour, minute, second }
    } = sentTime;
    
    const dateString = `${year}-${addZero(month)}-${addZero(day)}T${addZero(hour)}:${addZero(minute)}:${addZero(second)}Z`;
    if (!moment(dateString).isValid) {
      console.log({
        type: 'Invalid Date',
        sentTime,
        dateString,
      })
    }
    return {
        browserDate: moment(dateString).format('YYYY-MM-DD'),
        browserTime: moment(dateString).format('hh:mm a'),
        dateString,
    }
  }
  return null;
}

const groupBy = (key) => (array) => array.reduce((objectsByKeyValue, obj) => {
  const value = obj[key];
  objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
  return objectsByKeyValue;
}, {});

function generateItems(messages) {
  const enrichedMessages = messages.map((message) => {
    const convertedTime = getTime(message.sentTime);
    if (convertedTime) {
      return {
        ...message,
        browserDate: convertedTime.browserDate,
        browserTime: convertedTime.browserTime,
        dateString: convertedTime.dateString,
      }
    }
    return message;
  })

  const days = groupBy('browserDate')(enrichedMessages);
  const sortedDays = Object.keys(days).sort();
  const items = sortedDays.reduce((acc, date) => {
    const sortedMessages = days[date].sort((a, b) => new Date(a.dateString).getTime() - new Date(b.dateString).getTime());
    return acc.concat([{ type: "day", date, id: date }, ...sortedMessages]);
  }, []);

  return items;
}

const RenderItem = React.memo(({ data, style, index, selectedUser }) => {
  const item = data[index];
  return (
    <div style={style}>
      {item?.type && item?.type === "day" ? (
        <div className={styles.centerBox}>
          <span className={styles.dateBox}>
            {convertToReadableDate(item?.date)}
          </span>
        </div>
      ) : (
        <ChatMessage
          key={item.id}
          message={item}
          selectedUser={selectedUser}
          // diffInMinutes={diffInMinutes}
        />
      )}
    </div>
  );
});
