import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import EventService from "./eventService";

export const getEventList = createAsyncThunk(
  "eventList/getEventList",
  async (options, thunkAPI) => {
    try {
      let listings = await EventService.getEventList(options);
      return listings;
    } catch (error) {
      const message = error.response.data._embedded.errors[0].message;
      return thunkAPI.rejectWithValue(message);
    }
  }
);

const initialState = {
  eventList: [],
  isLoading: false,
  isError: false,
};

const eventList = createSlice({
  name: "eventList",
  initialState,
  reducers: {
    reset: (state) => {
      state.eventList = [];
      state.isError = false;
      state.error = "";
    },
    resetError: (state) => {
      state.isError = false;
      state.error = "";
    },
    updateEventList: (state, action) => {
      state.eventList = action.payload.eventList;
      state.totalSize = action.payload?.totalSize;
      state.nextOffset = action.payload?.nextOffset;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEventList.pending, (state) => {
        state.isLoading = true;
        state.eventList = [];
        state.isError = false;
        state.error = "";
      })
      .addCase(getEventList.fulfilled, (state, action) => {
        state.isLoading = false;
        state.eventList = action.payload.eventList;
      })
      .addCase(getEventList.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.error = action.payload;
      });
  },
});

export const { reset, resetError, updateEventList } = eventList.actions;

export default eventList.reducer;
