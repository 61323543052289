import { redirect } from "react-router-dom";
import { logout } from "../auth/authSlice";
import { store } from "../store";

const axios = require("axios");

export const axiosInstance = axios.create({
  baseURL: "https://api.jeevitam.net",
  headers: {
    "Content-Type": "application/json",
  },
});

axiosInstance.interceptors.request.use(
  (config) => {
    const uid = localStorage.getItem("UID");
    const authToken = localStorage.getItem("auth-token");
    if (uid) {
      config.headers.uid = uid;
      config.headers["auth-token"] = authToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use((res) => {
  if (res?.data?.errorId === "USER_NOT_AUTHENTICATED") {
    store.dispatch(logout());
    redirect("/login");
    return;
  }
  return res;
});
